<div class="section-header">
  <h1>Produtos / {{ keyword || providerSelected.name }}</h1>
</div>

<div class="section-content">
  <div class="product-listing">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-12 col-lg-3 order-2 order-lg-1 sticky">
          <div id="product-filter-nav" class="product-filter-nav mb-3">
            <div class="product-category">
              <h5 class="filter-heading text-center text-lg-left">
                Fornecedores
              </h5>
              <ul>
                <li
                  [ngClass]="{
                    selected: provider.name === providerSelected.name
                  }"
                  *ngFor="let provider of providers"
                  (click)="selectProvider(provider)"
                >
                  <a>{{ provider.name }} </a
                  ><span>({{ provider.productsCount }})</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-9 order-1 order-lg-2">
          <div class="row">
            <div class="col-12 product-listing-products">
              <div class="header-products">
                <h4 *ngIf="products?.length === 0">
                  Desculpe, nenhum resultado encontrado!
                </h4>
              </div>
              <div class="product-list row">
                <a
                  *ngFor="let product of products"
                  href="/produtos/{{ product.id }}"
                  (click)="onClickProduct(product)"
                  class="col-6 col-md-4 col-lg-3 manage-color-hover wow slideInUp product-list-main-item"
                  data-wow-delay=".2s"
                >
                  <div>
                    <div class="product-item">
                      <div class="item p-item-img">
                        <img
                          src="{{ linkImage + product.id + '.jpg' }}"
                          alt="images"
                          (error)="onImgErro($event)"
                        />
                        <div
                          class="text-center d-flex justify-content-center align-items-center"
                        >
                          <a class="listing-cart-icon">
                            <i class="fa fa-shopping-cart"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="p-item-detail">
                      <p
                        class="text-center p-item-price"
                        *ngIf="customer?.id && product.salePrice"
                      >
                        {{ product.salePrice | currency }}
                      </p>
                      <h6 class="text-center">Código: {{ product.id }}</h6>
                      <h6 class="text-center">
                        Cód. Barras: {{ product.barCode }}
                      </h6>
                      <h4 class="text-center p-item-name">
                        <a> {{ product.name }} </a>
                      </h4>
                    </div>
                  </div>
                </a>
              </div>
              <p-paginator
                *ngIf="refreshPaginator"
                [rows]="20"
                [totalRecords]="pageResponseProduct.totalRows || 0"
                (onPageChange)="onPageChange($event)"
              ></p-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
