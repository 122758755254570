import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private httpService: HttpService) {}

  sendEmail(subject: string, text: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`email`, { subject, text }).subscribe({
        next: (res: any) => {
          if (res?.ok) {
            resolve(true);
          } else {
            reject();
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao enviar sua mensagem!';
          reject(msg);
        },
      });
    });
  }
}
