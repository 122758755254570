export class Provider {
  id?: number;
  name?: string;
  officialName?: string;
  order?: number;
  productsCount?: number;

  getProviders(providers: Provider[], providerSelected: Provider) {
    return providers.filter(
      (prov) => prov.name === providerSelected.name
    ) as Provider[];
  }
}
