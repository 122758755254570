import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthConstants } from '../../config/auth-constants';
import { SessionStorageService } from '../../services/storage/session/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    public sessionStorageService: SessionStorageService,
    public router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.sessionStorageService
        .get(AuthConstants.TOKEN)
        .then((res) => {
          if (res) {
            this.router.navigate(['area-cliente']);
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((_err) => {
          resolve(true);
        });
    });
  }
}
