import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthConstants } from 'src/app/core/config/auth-constants';
import { environment } from 'src/environments/environment';
import { StorageConstants } from './../../core/config/storage-constants';
import { LoaderService } from './../../core/services/loader/loader.service';
import { ProductsService } from './../../core/services/products/products.service';
import { ProvidersService } from './../../core/services/providers/providers.service';
import { SessionStorageService } from './../../core/services/storage/session/session-storage.service';
import { Customer } from './../../shared/models/customer/customer';
import { PageResponse } from './../../shared/models/page/page-response';
import { Product } from './../../shared/models/products/product';
import { ProductFilter } from './../../shared/models/products/product-filter';
import { Provider } from './../../shared/models/providers/provider';

@Component({
  selector: 'app-products-page',
  templateUrl: './products.page.component.html',
  styleUrls: ['./products.page.component.scss'],
})
export class ProductsComponent implements OnInit {
  public customer: Customer = new Customer();
  public keyword = '';
  public page: number = 1;
  public pageResponseProduct: PageResponse = new PageResponse();
  public pageResponseProviders: PageResponse = new PageResponse();
  public products: Product[] = [];
  public providers: Provider[] = [];
  public providerSelected: Provider = new Provider();
  public refreshPaginator = true;
  public linkImage: string = environment.linkImages;

  constructor(
    private activeRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private sessionStorageService: SessionStorageService,
    private productsService: ProductsService,
    private providersService: ProvidersService
  ) {
    this.getUser();
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(async (params) => {
      this.keyword = params.keyword;
      let idProvider = params.idProvider;
      idProvider = idProvider ? Number(idProvider) : 11760;

      const sessionIdProvider = await this.sessionStorageService.get(
        StorageConstants.ID_PROVIDER
      );
      idProvider = sessionIdProvider ? Number(sessionIdProvider) : idProvider;
      this.getProviders(this.keyword ? 0 : idProvider);
    });
  }

  getUser() {
    this.sessionStorageService.get(AuthConstants.AUTH).then((res) => {
      this.customer = new Customer();
      if (res) {
        this.customer.fromJson(res);
      }
    });
  }

  async getProviders(idProvider: number) {
    this.products = [];
    await this.loaderService.showLoader();
    try {
      this.pageResponseProviders = await this.providersService.providers();
      if (this.pageResponseProviders) {
        (this.pageResponseProviders.list as Provider[]).forEach((prov1) => {
          const find = this.providers.find(
            (prov2) => prov1.name === prov2.name
          );

          if (find) {
            find.productsCount =
              (prov1.productsCount || 0) + (find.productsCount || 0);
          } else {
            this.providers.push(prov1);
          }
        });

        if (this.keyword) {
          this.getProductsKeyword();
        } else {
          this.providerSelected = this.pageResponseProviders.list?.find(
            (p) => p.id === idProvider
          );
          this.sessionStorageService.removeItem(StorageConstants.ID_PROVIDER);
          await this.getProducts();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  selectProvider(newProvider: Provider) {
    this.keyword = '';
    this.refreshPaginator = false;
    this.page = 1;
    this.providerSelected = newProvider;
    this.getProducts();
  }

  async getProductsKeyword() {
    this.products = [];
    await this.loaderService.showLoader();
    window.scroll(0, 0);
    try {
      this.pageResponseProduct = await this.productsService.productsKeyword(
        this.keyword,
        20,
        this.page
      );
      if (this.pageResponseProduct) {
        this.products = this.pageResponseProduct.list as Product[];
      }
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  async getProducts() {
    this.products = [];
    await this.loaderService.showLoader();
    window.scroll(0, 0);
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.providers = this.providerSelected.getProviders(
        this.pageResponseProviders.list as Provider[],
        this.providerSelected
      );
      this.pageResponseProduct = await this.productsService.products(
        filter,
        20,
        this.page
      );
      if (this.pageResponseProduct) {
        this.products = this.pageResponseProduct.list as Product[];
      }
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
      this.refreshPaginator = true;
    }
  }

  onImgErro(event: any) {
    event.target.src = 'assets/img/products/no-image.jpg';
  }

  onClickProduct(productSelected: Product) {
    if (productSelected) {
      if (this.providerSelected?.id) {
        this.sessionStorageService.set(
          StorageConstants.ID_PROVIDER,
          this.providerSelected.id
        );
      }
    }
  }

  onPageChange(event: any) {
    this.page = event.page + 1;
    if (this.keyword) {
      this.getProductsKeyword();
    } else {
      this.getProducts();
    }
  }
}
