<app-confirm-dialog></app-confirm-dialog>
<div class="section-content">
  <div class="signup-form">
    <div class="about_content">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-lg-8 offset-lg-2 text-center wow slideInDown"
            data-wow-duration="2s"
          >
            <p class="para_text">
              {{ customer.name }}
            </p>
            <p class="para_text">
              {{ customer.email }}
            </p>
            <p class="para_text">
              {{ customer.document || "" | mask : "CPF_CNPJ" }}
            </p>
          </div>
        </div>
      </div>

      <div class="container pr-lg-0 whitebox form-main">
        <div class="widget logincontainer">
          <div class="customer-area-buttons">
            <button class="btn btn black-btn-with-border">Meus Pedidos</button>
            <button
              (click)="onClickChangePassword()"
              class="btn pink-gradient-btn-into-black"
            >
              Alterar Senha
            </button>
            <button
              *ngIf="customer.admin"
              (click)="onClickSynchronize()"
              class="btn pink-gradient-btn-into-black"
            >
              Sincronizar
            </button>
            <button
              (click)="onClickLogout()"
              class="btn pink-gradient-btn-into-black"
            >
              Sair
            </button>
          </div>

          <div
            class="customer-area-content wow slideInUp"
            data-wow-duration="2s"
          >
            <app-deliveries [customer]="customer"></app-deliveries>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
