import { Component, Input } from '@angular/core';
import { Customer } from '../../models/customer/customer';
import { Product } from './../../models/products/product';

import { environment } from 'src/environments/environment';
import SwiperCore, { A11y, Autoplay, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Pagination, A11y]);

@Component({
  selector: 'app-carousel-products',
  templateUrl: './carousel-products.component.html',
  styleUrls: ['./carousel-products.component.scss'],
})
export class CarouselProductsComponent {
  @Input()
  public customer: Customer = new Customer();

  @Input()
  public products: Product[] = [];

  public linkImage: string = environment.linkImages;

  onImgErro(event: any) {
    event.target.src = 'assets/img/products/no-image.jpg';
  }
}
