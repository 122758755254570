<div class="section-header section-header-brand">
  <div class="container text-center">
    <p>
      A Distribuidora Santa Catarina trabalha com diversas marcas reconhecidas
      por sua qualidade e confiança.
    </p>
    <p>
      No estado de Santa Catarina somos distribuidores exclusivos de algumas
      marcas e também distribuímos
    </p>
    <p>
      outras diversas, ofertando uma gama de variedades de acordo com a
      necessidade de cada cliente
    </p>
  </div>
</div>

<div class="section-content brands">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center wow slideInUp" data-wow-duration="2s">
        <h3 class="heading">MARCAS EXCLUSIVAS</h3>
      </div>
    </div>
    <div class="row d-flex justify-content-center pt-5">
      <div
        class="col-6 col-md-4 col-lg-3 d-flex wow slideInUp brand-card"
        data-wow-duration="2s"
        *ngFor="let providerEx of providersExclusives"
        (click)="onClickProvider(providerEx)"
      >
        <div class="brand-box">
          <img
            src="{{ linkBrands + providerEx.id }}.png"
            alt="img"
            (error)="onImgErro($event)"
          />
        </div>
        <p>{{ providerEx.name }}</p>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-md-12 text-center wow slideInUp" data-wow-duration="2s">
        <h3 class="heading">MARCAS</h3>
      </div>
    </div>
    <div class="row d-flex justify-content-center pt-5">
      <div
        class="col-6 col-md-4 col-lg-3 d-flex wow slideInUp brand-card"
        data-wow-duration="2s"
        *ngFor="let provider of providers"
        (click)="onClickProvider(provider)"
      >
        <div class="brand-box">
          <img
            src="{{ linkBrands + provider.id }}.png"
            alt="img"
            (error)="onImgErro($event)"
          />
        </div>
        <p>{{ provider.name }}</p>
      </div>
    </div>
  </div>
</div>
