import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { EmailService } from './../../core/services/email/email.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact.page.component.html',
  styleUrls: ['./contact.page.component.scss'],
})
export class ContactComponent implements OnInit, AfterViewInit {
  postData = {
    email: '',
    name: '',
    phone: '',
    subject: '',
    message: '',
  };

  constructor(
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }

  onClickSend() {
    if (this.validFields()) {
      this.sendEmail();
    } else {
      this.toastr.error(
        'Por favor, preencha todos os campos para enviar sua mensagem!',
        'Campo obrigatório!'
      );
    }
  }

  async sendEmail() {
    await this.loaderService.showLoader();
    try {
      const msg = `Nome: ${this.postData.name}<br>E-mail: ${
        this.postData.email
      }<br>Telefone: ${this.postData.phone}<br>Assunto: ${
        this.postData.subject
      }<br><br>Mensagem: <br>${this.postData.message
        .split('\n')
        .join('<br/>')}`;
      await this.emailService.sendEmail(this.postData.email, msg);
      this.cleanFields();
      this.toastr.success('Sua mensagem foi enviada com sucesso!', 'Sucesso!');
    } catch (error: any) {
      this.toastr.error(error, 'Ocorreu um erro!');
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  cleanFields() {
    this.postData.email = '';
    this.postData.message = '';
    this.postData.name = '';
    this.postData.phone = '';
    this.postData.subject = '';
  }

  validFields() {
    return (
      this.postData?.email?.trim()?.length > 0 &&
      this.postData?.message?.trim()?.length > 0 &&
      this.postData?.name?.trim()?.length > 0 &&
      this.postData?.phone?.trim()?.length > 0 &&
      this.postData?.subject?.trim()?.length > 0
    );
  }
}
