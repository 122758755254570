import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoaderService } from './../../core/services/loader/loader.service';

@Component({
  selector: 'app-login.page',
  templateUrl: './login.page.component.html',
  styleUrls: ['./login.page.component.scss'],
})
export class LoginPageComponent implements OnInit, AfterViewInit {
  postData = {
    document: '',
    password: '',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }

  async login() {
    if (this.validateInputs()) {
      await this.loaderService.showLoader();
      try {
        await this.authService.login(
          this.postData.document,
          this.postData.password
        );
      } catch (error: any) {
        this.toastr.error(error, 'Erro ao efetuar login!');
      } finally {
        await this.loaderService.hideLoader();
      }
    } else {
      this.toastr.error(
        'Por favor, informe seu CNPJ e sua senha corretamente!',
        'Campo Obrigatório!'
      );
    }
  }

  validateInputs() {
    return (
      this.postData?.document?.trim()?.length > 13 &&
      this.postData?.password?.trim()?.length > 0
    );
  }

  firstAccess() {
    this.router.navigate(['primeiro-acesso']);
  }

  forgot() {
    this.router.navigate(['recuperar-senha']);
  }
}
