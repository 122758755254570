import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { environment } from 'src/environments/environment';
import { Customer } from './../../models/customer/customer';
import { CustomerDeliver } from './../../models/customer/deliver/customer-deliver';
import { CustomerDeliverItem } from './../../models/customer/deliver/customer-deliver-item';
import { PageResponse } from './../../models/page/page-response';

@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss'],
})
export class DeliveriesComponent implements OnInit {
  @Input()
  public customer: Customer = new Customer();

  public deliveries: CustomerDeliver[] = [];
  public page: number = 1;
  public pageResponseDeliveries: PageResponse = new PageResponse();
  public linkImage: string = environment.linkImages;

  constructor(
    private loaderService: LoaderService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.getDeliveries();
  }

  async getDeliveries() {
    window.scroll(0, 0);
    this.deliveries = [];
    await this.loaderService.showLoader();
    try {
      this.pageResponseDeliveries = await this.customerService.deliveries(
        10,
        this.page
      );
      if (this.pageResponseDeliveries) {
        this.deliveries = this.pageResponseDeliveries.list as CustomerDeliver[];
      }
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  onRowExpand(event: any) {
    if (event) {
      this.getDeliver(event.data);
    }
  }

  async getDeliver(deliver: CustomerDeliver) {
    if (!deliver.isComplete) {
      await this.loaderService.showLoader();
      try {
        const res = await this.customerService.delivery(deliver.id);
        if (res) {
          const index = this.deliveries.findIndex((d) => d.id === deliver.id);
          this.deliveries[index] = res;
        }
      } catch (error) {
        console.log(error);
      } finally {
        await this.loaderService.hideLoader();
      }
    }
  }

  haveDeliveries() {
    return this.deliveries?.length > 0;
  }

  getTotalValue(itens: CustomerDeliverItem[]) {
    let total = 0;
    if (itens?.length > 0) {
      itens.forEach((item) => {
        total = total + (item.quantity || 1) * (item?.currentPrice || 0);
      });
    }
    return total;
  }

  onPageChange(event: any) {
    this.page = event.page + 1;
    this.getDeliveries();
  }
}
