import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(appRouter: Router) {
    appRouter.events.subscribe(() => {
      (window as any).pageBootstrap();
      (window as any).pageJQueryBootstrap();
    });
  }
}
