<div class="section-header">
  <h1>Política de Cookies</h1>
</div>
<div class="section-content">
  <div class="container">
    <p>
      Nós presamos pela beleza, pelo cuidado e pelo bem-estar de todos os nossos
      clientes, inclusive quando o assunto é a transparência com o tratamento
      dos seus dados.
    </p>
    <p>
      A confiança depositada pelos nossos clientes passa pelo esclarecimento
      sobre os cookies utilizados em nosso site, os quais tem a intenção de
      auxiliar a sua navegação e possibilitar a comunicação de assuntos que
      sejam do seu interesse.
    </p>

    <h2>O QUE SÃO COOKIES?</h2>
    <p>
      São pacotes de dados responsáveis por identificar quais caminhos você
      percorre dentro do nosso site, informando se toda operação está
      funcionando regularmente e quais os seus padrões de interesse.
    </p>
    <p>
      Por meio das informações coletadas, é possível determinar quais anúncios
      são mais interessantes para você, evitando que perca tempo com assuntos
      desnecessários ou pouco relevantes.
    </p>
    <p>
      Os cookies também nos permitem identificar qual dispositivo você está
      usando para acessar o nosso site e manter armazenadas as suas informações
      de login, facilitando o acesso em uma próxima visita.
    </p>
    <p>
      Essa ferramenta também possibilita a identificação de tentativas de
      fraude, protegendo o usuário.
    </p>

    <h2>QUAIS OS TIPOS DE COOKIES UTILIZADOS?</h2>
    <p>
      Cookies persistentes: são responsáveis por manter armazenado em seu
      dispositivo o login e a senha utilizados no último acesso caso você
      selecione a opção “permanecer conectado”, facilitando assim o seu novo
      acesso e compra.
    </p>
    <p>
      Cookies de sessão: acompanha o trajeto percorrido pelo usuário dentro do
      site no período em que o utiliza, verificando se tudo está funcionando
      conforme o previsto e verificando quais são as suas preferências.
    </p>

    <h2>COMO FAZER O CONTROLE DOS COOKIES?</h2>
    <p>
      Ao acessar e navegar pelo nosso site, você concorda com o tratamento de
      informações por meio de cookies, ficando estes habilitados
      automaticamente.
    </p>
    <p>
      Você poderá desabilitar os cookies diretamente no seu navegador a qualquer
      tempo, por meio das configurações. Porém, para realizar pedidos, é
      necessário manter os cookies ativados, podendo desativá-los na sequência.
    </p>
    <p>
      Ao desativar os cookies você não terá mais disponível a personalização da
      experiência do nosso site, por isso aconselhamos a mantê-los ativados.
    </p>

    <h2>ALTERAÇÃO DA POLÍTICA DE COOKIES</h2>
    <p>
      A Santa Catarina Distribuidora se mantém no direito de alterar a presente
      política a qualquer momento caso verifique a necessidade, mantendo o
      usuário sempre informado sobre eventuais modificações.
    </p>

    <h2>GERAL</h2>
    <p>
      A presente Política de Cookies não tem fins contratuais, mas mantém o
      usuário informado garantindo o Princípio da Transparência e
      Autodeterminação Informativa definidos em legislação específica.
    </p>

    <h2>ENTRE EM CONTATO</h2>
    <p>
      Para entrar em contato com a Santa Catarina Distribuidora para retirada de
      dúvidas e solicitar informações, basta usar um dos canais de atendimento
      informados no campo “Fale Conosco”.
    </p>
    <p>Última versão: novembro de 2022</p>
  </div>
</div>
