import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';

@Component({
  selector: 'app-terms.page',
  templateUrl: './terms.page.component.html',
  styleUrls: ['./terms.page.component.scss'],
})
export class TermsPageComponent implements OnInit, AfterViewInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }
}
