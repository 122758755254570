<div class="about_content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="product-body">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb text-center text-lg-left">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item">
                <a routerLink="/produtos">Produtos</a>
              </li>
              <li class="breadcrumb-item">
                <a (click)="onClickProvider()">{{ provider.name }}</a>
              </li>
              <li class="breadcrumb-item">
                <a routerLink="{{ '/produtos/' + product.id }}">{{
                  product.id
                }}</a>
              </li>
            </ol>
          </nav>

          <div class="pro-detail-sec row">
            <div class="col-12">
              <h4 class="pro-heading text-center text-lg-left">
                {{ product.name }}
              </h4>
            </div>
          </div>
          <div class="row product-list product-detail" *ngIf="product.id > 0">
            <div class="col-12 col-lg-6 product-detail-slider">
              <div class="wrapper">
                <div class="Gallery swiper-container img-magnifier-container">
                  <div class="swiper-wrapper myimgs">
                    <div class="swiper-slide">
                      <a
                        href="{{ linkImage + product.id + '.jpg' }}"
                        data-fancybox="1"
                        title="Zoom In"
                        ><img
                          class="myimage"
                          src="{{ linkImage + product.id + '.jpg' }}"
                          alt="images"
                          (error)="onImgErro($event)"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-lg-6 text-center text-lg-left product-informations"
            >
              <div
                class="product-single-price"
                *ngIf="customer?.id && product.salePrice"
              >
                <h4>
                  <span class="real-price">{{
                    product.salePrice | currency
                  }}</span>
                </h4>
              </div>

              <div class="product-checklist">
                <ul>
                  <li>
                    <i class="fas fa-check"></i> Satisfação 100% garantida.
                  </li>
                  <li>
                    <i class="fas fa-check"></i> Produtos com qualidade
                    comprovada.
                  </li>
                  <li>
                    <i class="fas fa-check"></i> Entregamos para toda Santa
                    Catarina.
                  </li>
                  <li><i class="fas fa-check"></i> Frete gratuito.</li>
                </ul>
              </div>

              <div class="row product-quantity input_plus_mins no-gutters">
                <div
                  class="qty col-12 col-lg-3 d-lg-flex align-items-lg-center d-inline-block"
                >
                  <span class="minus bg-dark"
                    ><i class="lni-minus" (click)="onClickMinus()"></i
                  ></span>
                  <input
                    type="number"
                    class="count"
                    value="{{ quantity }}"
                    disabled
                  />
                  <span class="plus bg-dark"
                    ><i class="lni-plus" (click)="onClickMore()"></i
                  ></span>
                </div>
                <div class="col-12 col-lg-9">
                  <button
                    (click)="onClickAddToCart()"
                    class="btn pink-gradient-btn-into-black"
                  >
                    ADICIONAR AO CARRINHO
                  </button>
                </div>
              </div>

              <div class="dropdown-divider"></div>

              <div class="product-tags-list">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <p class="d-inline">
                        Código de barras : <span>{{ product.barCode }}</span>
                      </p>
                    </li>
                    <li class="breadcrumb-item">
                      <span class="d-inline"
                        >Fornecedor:
                        <a (click)="onClickProvider()">{{
                          provider.name
                        }}</a></span
                      >
                    </li>
                  </ol>
                </nav>
              </div>

              <div class="row">
                <div class="col-12">
                  <button
                    (click)="onClickContinueShop()"
                    class="btn pink-color-black-gradient-btn"
                  >
                    CONTINUAR COMPRANDO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lastest_arrivals remove-margins">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4">
          <h1>Mais Produtos</h1>
        </div>

        <div class="col-12">
          <app-carousel-products
            [customer]="customer"
            [products]="productsMore"
          ></app-carousel-products>
        </div>
      </div>
    </div>
  </div>
</div>
