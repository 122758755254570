<div class="section-header">
  <h1>Aviso de Privacidade</h1>
</div>

<div class="section-content">
  <div class="container">
    <p>
      Olá! Sabemos que políticas de privacidade podem ser extensas e confusas,
      mas queremos que os nossos clientes possuam a melhor experiência e tenham
      consciência sobre como os seus dados são tratados. Por isso, estamos
      dispostos a sermos claros, proporcionando uma experiência de compra fácil
      e segura.
    </p>
    <p>
      Nós, como responsáveis pelo tratamento das informações de nossos clientes,
      temos consciência das nossas obrigações relacionadas ao direito de
      informação, de segurança e demais disposições expostas em legislações
      específicas, por isso queremos solicitar que leia atentamente os tópicos a
      seguir. As suas informações nos auxiliam a prestar um melhor serviço.
    </p>

    <h2>POLÍTICA DE PRIVACIDADE SANTA CATARINA DISTRIBUIDORA</h2>
    <p>
      Ao entrar em contato conosco por um dos nossos canais de atendimento, você
      realizará um cadastro para poder concluir as suas compras. São coletados o
      nome/razão social, o CPF/CNPJ, telefone, endereço, e-mail e dados
      bancários. Este cadastro poderá ser realizado por telefone ou por
      atendimento presencial.
    </p>
    <p>
      Informamos ainda que também deixaremos salvo nos nossos registros os seus
      dados de compra, tanto para podermos concluir a transação, quanto para
      cumprir obrigações legais fiscais.
    </p>
    <p>
      No site será cadastrado um login e senha de acesso e por meio desta
      plataforma você poderá conferir toda a linha de produtos e preços da nossa
      empresa, porém nenhuma compra é finalizada diretamente no site, sendo
      armazenado na plataforma somente o login e a senha de acesso.
    </p>
    <p>
      Após a realização de um orçamento pelo site, as informações coletadas são
      encaminhadas para os atendentes internos e estes finalizarão a compra. As
      informações financeiras do cliente ficam armazenadas internamente na
      empresa, não no site.
    </p>
    <p>
      Estas informações nos auxiliam a efetivar a sua compra, encaminhando os
      produtos para o seu endereço e mantendo um canal de contato para qualquer
      necessidade que possa surgir, como cobranças de pagamento e garantia dos
      produtos.
    </p>
    <p>
      Mesmo após você ter concluído a compra, estes dados ficam armazenados no
      nosso servidor interno que apenas poderá ser acessado pelos nossos
      colaboradores mediante o fornecimento de senhas.
    </p>
    <p>
      Os seus dados ficam armazenados no nosso servidor a fim de proporcionar
      uma melhor experiência de compra na próxima vez que você desejar adquirir
      um novo produto. Informamos que você poderá solicitar a exclusão das
      informações conforme exposto nesta política.
    </p>
    <p>
      Os cookies coletados no nosso site serão usados com o fim de encaminhar o
      seu orçamento aos nossos colaboradores, finalizando a compra, e para
      possibilitar o envio de mensagens marketing, conforme previsto em tópicos
      específicos.
    </p>
    <p>
      Os dados citados são tratados com fundamento nas bases legais de execução
      de contrato, obrigação legal e consentimento.
    </p>

    <h2>COOKIES</h2>
    <p>
      Quando você navega pelo nosso site, são coletadas automaticamente,
      conforme descrito na Política de Cookies, informações sobre as suas
      preferências, para que assim possamos encaminhar publicidades de acordo
      com o que você mais gostou.
    </p>
    <p>
      Também ficam armazenados os acessos usados para compras online,
      facilitando assim a sua próxima compra.
    </p>
    <p>
      Os cookies que usamos são obrigatórios e poderão ser desativados
      diretamente no seu navegador.
    </p>
    <p>
      Solicitamos que verifique a nossa Política de Cookies para obter maiores
      informações.
    </p>

    <h2>COMPARTILHAMENTO</h2>
    <p>
      Nós não vendemos, repassamos ou alugamos os seus dados, apenas
      compartilhamos com os nossos parceiros para as seguintes atividades:
    </p>

    <p>
      - Possibilitar a entrega das suas compras por empresas prestadoras deste
      serviço, limitando-se aos seguintes dados cadastrais: nome/razão social,
      CPF/CNPJ, telefone e endereço;
    </p>
    <p>
      - Operações de marketing com base nos cookies coletados durante a sua
      navegação pelo nosso site e login de acesso utilizado na plataforma, bem
      como os dados de compra;
    </p>
    <p>
      - Possibilitar a prestação dos serviços de venda, os quais incluem a
      empresa fornecedora do sistema operacional interno, o site e demais
      operações necessárias ao fiel cumprimento das solicitações dos clientes,
      priorizando o princípio da necessidade no compartilhamento de informações
      do Titular de Dados;
    </p>
    <p>
      - Operações de crédito por meio de empresas parceiras, possibilitando a
      compra por cartão de crédito ou boleto bancário. São fornecidos nome/razão
      social, CPF/CNPJ, telefone, endereço, e-mail, dados de compra e dados
      bancários;
    </p>
    <p>
      - Operações de proteção de crédito a fim de resguardar a empresa de
      eventuais irregularidades e inconsistências: nome/razão social, CPF/CNPJ,
      telefone, endereço, e-mail e dados de compra;
    </p>
    <p>
      Também poderão ser compartilhadas informações dos nossos clientes a fim de
      cumprir determinação legal dos órgãos competentes, por ordem judicial ou
      administrativa, para resguardar direito da empresa Operadora ou o seu
      legítimo interesse, bem como atender solicitação da Autoridade Nacional de
      Proteção de Dados.
    </p>
    <p>
      Em todas as hipóteses, são priorizados os interesses do cliente e
      garantidos os direitos definidos em legislação específica.
    </p>

    <h2>COMPARTILHAMENTO INTERNACIONAL DE DADOS</h2>
    <p>
      Em razão de usarmos recursos tecnológicos disponíveis por meio de
      websites, alguns dos serviços que utilizamos são fornecidos por empresas
      com sede fora do Brasil, assim como no caso de subcontratações dos nossos
      parceiros, de modo a ocorrer eventuais compartilhamentos internacionais de
      dados.
    </p>
    <p>
      No caso de transferências internacionais, nós seguiremos adotando
      critérios rígidos de segurança e priorizaremos os seus interesses, a fim
      de prevenir qualquer irregularidade de tratamento.
    </p>

    <h2>DIREITOS DOS TITULARES</h2>
    <p>
      Os titulares poderão requerer, a qualquer tempo, as suas informações
      armazenadas no nosso banco de cadastros e esclarecimentos acerca do
      tratamento de dados por meio do e-mail: comercial@distribuidorasc.com.br.
    </p>
    <p>
      Você poderá solicitar a anonimização do seu nome, endereço, telefone e
      e-mail ao entrar em contato conosco pelo canal anteriormente citado após a
      conclusão de todas as suas compras conosco, resguardados os prazos legais
      aplicados à relação de compra e venda de mercadorias.
    </p>
    <p>
      A empresa se compromete a manter o cadastro do cliente atualizado conforme
      dados fornecidos por este, cabendo ao Titular informar sobre eventual
      inconsistência ou alteração.
    </p>
    <p>
      Também é garantida ao Titular a portabilidade dos seus dados armazenados
      no nosso banco de dados mediante solicitação através do nosso e-mail.
    </p>
    <p>
      Na hipótese de você não ter o interesse de permanecer recebendo as nossas
      mensagens de marketing, poderá revogar o consentimento de tratamento das
      suas informações para este fim por meio do ícone ao final de cada e-mail
      ou por mensagem encaminhado ao nosso e-mail anteriormente destacado.
    </p>
    <p>
      A empresa se priva no direito de permanecer com os demais dados das
      compras efetuadas para o seu regular funcionamento, por obrigação legal
      com base no Código Tributário Nacional e para seu exercício regular de
      direito na hipótese reclamação judicial atrelada as compras realizadas.
    </p>
    <p>
      No caso da solicitação de algum dos direitos expostos nesta política,
      poderá ser solicitada a confirmação de identidade.
    </p>

    <h2>CRIANÇA OU ADOLESCENTE</h2>
    <p>
      Nós não vendemos para menores de 18 anos. Somente os pais ou responsáveis
      poderão adquirir os nossos produtos e, caso assim desejarem, repassar para
      o menor. Não nos responsabilizamos por eventuais danos causados a criança
      ou adolescente que tiver as informações do nosso site e produtos
      repassados pelo seu responsável legal.
    </p>

    <h2>ARMAZENAMENTO</h2>
    <p>
      Todos os dados são mantidos em nosso sistema pelo período definido em Lei,
      em especial com relação às determinações contratuais e fiscais.
    </p>
    <p>
      Também mantemos armazenadas as informações de compra, a fim de entender as
      preferências dos nossos clientes, oferecendo um melhor serviço, e para os
      legítimos interesses apresentados pela empresa ao longo do tempo, entre
      eles, a prevenção à fraudes e fiel cumprimento da prestação de serviços.
    </p>
    <p>
      Caso o Titular de Dados requeira, as informações cadastrais poderão ser
      anonimizadas e a sua identidade preservada, garantindo-se, contudo, o
      cumprimento de obrigações legais assumidas pela empresa.
    </p>

    <h2>DECISÕES AUTOMATIZADAS</h2>
    <p>
      Utilizamos recursos tecnológicos para tomada de decisões automatizadas, em
      especial para prevenção de riscos e proteção dos nossos serviços.
    </p>
    <p>
      Na hipótese de verificação de alguma inconsistência nas decisões
      automáticas, entre em contato com: comercial@distribuidorasc.com.br.
    </p>

    <h2>SEGURANÇA</h2>
    <p>
      Nós dispomos de barreiras de segurança físicas e eletrônicas, protegendo
      as suas informações e buscando prevenir que eventuais invasões ocorram,
      sempre prezando pelos meios tecnológicos disponíveis para tal fim.
    </p>
    <p>
      As barreiras físicas são estabelecidas por medidas como o controle de
      acesso aos ambientes da empresa e orientação dos colaboradores quanto ao
      armazenamento de informações na forma física, não permitindo que estejam
      em locais de fácil acesso.
    </p>
    <p>
      Os nossos funcionários são treinados e orientados a não vender, alugar ou
      fornecer de qualquer modo os dados dos nossos clientes, respeitando a sua
      privacidade.
    </p>

    <h2>ALTERAÇÃO</h2>
    <p>
      A presente política poderá ser alterada a qualquer tempo pela empresa,
      cabendo a esta informar ao Titular sobre eventuais modificações.
    </p>
    <p>Este documento foi atualizado em 25 de novembro de 2022.</p>

    <p>Canais de comunicação:</p>
    <p>Santa Catarina Distribuidora</p>
    <p>comercial@distribuidorasc.com.br</p>
    <p>(48) 3346-0098</p>
    <p>Autoridade Nacional de Proteção de Dados</p>
    <p>anpd@anpd.gov.br</p>
    <p>(61) 3411-5961</p>
  </div>
</div>
