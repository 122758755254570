import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { PageRequest } from './../../../shared/models/page/page-request';
import { PageResponse } from './../../../shared/models/page/page-response';
import { Product } from './../../../shared/models/products/product';
import { ProductFilter } from './../../../shared/models/products/product-filter';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private httpService: HttpService) {}

  products(
    filter: ProductFilter,
    itensPerPage: number,
    page: number = 1
  ): Promise<PageResponse> {
    let content: PageResponse;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `products/?${filter.getUrl()}itensPerPage=${itensPerPage}&page=${page}&orderBy=name`
        )
        .subscribe({
          next: (res: any) => {
            content = Object.assign(new PageResponse(), res);
            content.pageRequest = Object.assign(
              new PageRequest(),
              res.pageRequest
            );
            const products: Product[] = [];
            content.list?.forEach((product) => {
              if (product.salePrice) {
                product.salePrice = product.salePrice / 100;
              }
              products.push(Object.assign(new Product(), product));
            });
            content.list = products;
            resolve(content);
          },
          error: (error: any) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os produtos.';
            reject(msg);
          },
        });
    });
  }

  product(id: string): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`products/${id}`).subscribe({
        next: (res: any) => {
          const product = Object.assign(new Product(), res);
          if (product.salePrice) {
            product.salePrice = product.salePrice / 100;
          }
          resolve(product);
        },
        error: (error: any) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao busca o produto.';
          reject(msg);
        },
      });
    });
  }

  productsKeyword(
    keyword: string,
    itensPerPage: number,
    page: number = 1
  ): Promise<PageResponse> {
    let content: PageResponse;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `products/query/${keyword}?itensPerPage=${itensPerPage}&page=${page}&orderBy=name`
        )
        .subscribe({
          next: (res: any) => {
            content = Object.assign(new PageResponse(), res);
            content.pageRequest = Object.assign(
              new PageRequest(),
              res.pageRequest
            );
            const products: Product[] = [];
            content.list?.forEach((product) => {
              if (product.salePrice) {
                product.salePrice = product.salePrice / 100;
              }
              products.push(Object.assign(new Product(), product));
            });
            content.list = products;
            resolve(content);
          },
          error: (error: any) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os produtos.';
            reject(msg);
          },
        });
    });
  }
}
