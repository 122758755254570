export const validEmail = (email: string): boolean => {
  const valid = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  ).test(email);
  return valid;
};

export const validatePassword = (
  password: string,
  confirmPassword: string
): string => {
  if (password?.length === 0 || confirmPassword?.length === 0) {
    return 'Por favor, informe e confirme sua nova senha!';
  }

  if (password !== confirmPassword) {
    return 'Senha e confirmação são diferentes!';
  }

  const valid = new RegExp('^^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])').test(
    password
  );

  if (password.length < 8 || !valid) {
    return 'Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números';
  }
  return '';
};
