import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { PageResponse } from 'src/app/shared/models/page/page-response';
import { environment } from 'src/environments/environment';
import { ProvidersService } from './../../core/services/providers/providers.service';
import { Provider } from './../../shared/models/providers/provider';

@Component({
  selector: 'app-brands-page',
  templateUrl: './brands.page.component.html',
  styleUrls: ['./brands.page.component.scss'],
})
export class BrandsComponent implements OnInit {
  public pageResponseProviders: PageResponse = new PageResponse();
  public providers: Provider[] = [];
  public providersExclusives: Provider[] = [];
  public linkBrands: string = environment.linkBrands;

  constructor(
    private loaderService: LoaderService,
    private providersService: ProvidersService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getProviders();
  }

  async getProviders() {
    await this.loaderService.showLoader();
    try {
      this.pageResponseProviders = await this.providersService.providers();
      if (this.pageResponseProviders) {
        this.providers = this.pageResponseProviders.list as Provider[];
        this.providers = this.providers.filter(
          (prov) =>
            prov === this.providers.find((prov2) => prov.name === prov2.name)
        );

        this.providersExclusives = this.providers.filter(
          (prov) =>
            prov.id === 11760 ||
            prov.id === 9136 ||
            prov.id === 13112 ||
            prov.id === 13097 ||
            prov.id === 13220 ||
            prov.id === 13197
        );
      }
    } catch (error: any) {
      this.toastr.error(error, 'Ocorreu um erro!');
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  onClickProvider(provider: Provider) {
    this.router.navigateByUrl(`produtos?idProvider=${provider.id}`);
  }

  onImgErro(event: any) {
    event.target.src = 'assets/img/brands/no-image.jpg';
  }
}
