<app-confirm-dialog></app-confirm-dialog>
<div class="section-content">
  <div class="about_content">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center wow slideInUp" data-wow-duration="2s">
          <h1 class="heading">Carrinho</h1>
        </div>
      </div>
    </div>

    <div class="shop-cart wow slideInUp" data-wow-duration="2s">
      <div class="container">
        <app-no-itens
          *ngIf="!haveProductsCart()"
          [title]="'VOCÊ NÃO POSSUI PRODUTOS NO CARRINHO!'"
        ></app-no-itens>
        <div class="row" *ngIf="haveProductsCart()">
          <div
            class="col-12 col-md-12 cart_table wow fadeInUp animated"
            data-wow-delay="300ms"
            style="
              visibility: visible;
              animation-delay: 300ms;
              animation-name: fadeInUp;
            "
          >
            <div class="table-responsive">
              <table class="table table-bordered border-radius">
                <thead>
                  <tr>
                    <th class="darkcolor">Produto</th>
                    <th class="darkcolor mobile-invisible">Valor</th>
                    <th class="darkcolor mobile-invisible">Quantidade</th>
                    <th class="darkcolor mobile-invisible">Total</th>
                    <th class="mobile-invisible"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let pc of productsCart">
                    <td>
                      <div class="d-table product-detail-cart">
                        <div class="media">
                          <div class="row no-gutters">
                            <div
                              class="col-12 col-lg-2 product-detail-cart-image"
                            >
                              <a
                                class="shopping-product"
                                routerLink="/produtos/{{ pc?.product?.id }}"
                                ><img
                                  src="{{
                                    linkImage + pc?.product?.id + '.jpg'
                                  }}"
                                  alt="Generic placeholder image"
                              /></a>
                            </div>

                            <div
                              class="col-12 col-lg-10 product-detail-cart-data"
                            >
                              <div class="media-body ml-lg-3">
                                <h4 class="product-name">
                                  <a
                                    routerLink="/produtos/{{ pc?.product?.id }}"
                                    >{{ pc?.product?.name }}</a
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="product-details-cart-mobile">
                        <h4 class="text-center amount">
                          {{ pc?.product?.salePrice || 0 | currency }}
                        </h4>
                        <div class="quote text-center mt-1">
                          <input
                            type="number"
                            placeholder="1"
                            class="quote"
                            min="1"
                            max="100"
                            value="{{ pc?.quantity }}"
                            (change)="onChangeQuantity($event, pc)"
                          />
                        </div>
                        <h4 class="text-center amount total-value">
                          {{
                            (pc?.product?.salePrice || 0) * (pc?.quantity || 1)
                              | currency
                          }}
                        </h4>
                        <a class="btn-close" (click)="onClickDeleteProduct(pc)"
                          ><i class="lni-trash"></i
                        ></a>
                      </div>
                    </td>
                    <td class="mobile-invisible">
                      <h4 class="text-center amount">
                        {{ pc?.product?.salePrice || 0 | currency }}
                      </h4>
                    </td>
                    <td class="text-center mobile-invisible">
                      <div class="quote text-center mt-1">
                        <input
                          type="number"
                          placeholder="1"
                          class="quote"
                          min="1"
                          max="100"
                          value="{{ pc?.quantity }}"
                          (change)="onChangeQuantity($event, pc)"
                        />
                      </div>
                    </td>
                    <td class="darkcolor mobile-invisible">
                      <h4 class="text-center amount">
                        {{
                          (pc?.product?.salePrice || 0) * (pc?.quantity || 1)
                            | currency
                        }}
                      </h4>
                    </td>
                    <td class="text-center mobile-invisible">
                      <a class="btn-close" (click)="onClickDeleteProduct(pc)"
                        ><i class="lni-trash"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="apply_coupon">
              <div class="row">
                <div class="col-12 text-right total-value">
                  Valor total: {{ getTotalValue() | currency }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <a
                    class="btn pink-color-black-gradient-btn"
                    (click)="onClickCheckout()"
                    >FINALIZAR COMPRA</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
