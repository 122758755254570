import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthConstants } from 'src/app/core/config/auth-constants';
import { SessionStorageService } from '../storage/session/session-storage.service';
import { ProductCart } from './../../../shared/models/products/product-cart';
import { StorageConstants } from './../../config/storage-constants';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  countProductsCart$ = new BehaviorSubject<any>('');

  constructor(private sessionStorageService: SessionStorageService) {}

  addToCart(product: ProductCart): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let productsCart: ProductCart[] = [];
      setTimeout(async () => {
        try {
          const products = await this.getProducts();
          if (products?.length > 0) {
            productsCart = products;
            const existingProduct = productsCart.find(
              (pc) => pc?.product?.id === product?.product?.id
            );
            if (existingProduct) {
              existingProduct.quantity =
                (existingProduct.quantity || 1) + (product.quantity || 1);
            } else {
              productsCart.push(product);
            }
          } else {
            productsCart.push(product);
          }
          await this.sessionStorageService.set(
            StorageConstants.PRODUCTS_CART,
            productsCart
          );
          this.countProductsCart$.next(productsCart.length || 0);
          resolve(true);
        } catch (error) {
          reject(false);
        }
      }, 500);
    });
  }

  deleteProduct(product: ProductCart): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let productsCart: ProductCart[] = [];
      setTimeout(async () => {
        try {
          const products = await this.getProducts();
          if (products?.length > 0) {
            productsCart = products;
            const indexProduct = productsCart.findIndex(
              (pc) => pc?.product?.id === product?.product?.id
            );
            productsCart.splice(indexProduct, 1);
          }
          await this.sessionStorageService.set(
            StorageConstants.PRODUCTS_CART,
            productsCart
          );
          this.countProductsCart$.next(productsCart.length || 0);
          resolve(true);
        } catch (error) {
          reject(false);
        }
      }, 500);
    });
  }

  async getProducts(): Promise<ProductCart[]> {
    const user = await this.sessionStorageService.get(AuthConstants.AUTH);
    const productsCart: ProductCart[] = [];
    return new Promise(async (resolve, reject) => {
      if (user) {
        try {
          const products = await this.sessionStorageService.get(
            StorageConstants.PRODUCTS_CART
          );
          if (products) {
            products.forEach((prod: any) => {
              productsCart.push(Object.assign(new ProductCart(), prod));
            });
          }
          this.countProductsCart$.next(productsCart.length || 0);
          resolve(productsCart);
        } catch (error) {
          reject();
        }
      }
    });
  }

  changeQuantity(product: ProductCart): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const products = await this.getProducts();
          if (products?.length > 0) {
            const existingProduct = products.find(
              (pc) => pc?.product?.id === product?.product?.id
            );
            if (existingProduct) {
              existingProduct.quantity = product.quantity;
            }
          }
          await this.sessionStorageService.set(
            StorageConstants.PRODUCTS_CART,
            products
          );
          this.countProductsCart$.next(products.length || 0);
          resolve(true);
        } catch (error) {
          reject(false);
        }
      }, 500);
    });
  }

  clear(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.sessionStorageService.removeItem(
          StorageConstants.PRODUCTS_CART
        );
        this.countProductsCart$.next(0);
        resolve(true);
      } catch (error) {
        reject();
      }
    });
  }

  async getCountProducts() {
    await this.getProducts();
  }
}
