import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from '../storage/session/session-storage.service';
import { AuthConstants } from './../../config/auth-constants';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token$ = new BehaviorSubject<any>('');

  constructor(private sessionStorageService: SessionStorageService) {}

  getToken() {
    this.sessionStorageService.get(AuthConstants.TOKEN).then((res) => {
      return this.token$.next(res);
    });
  }
}
