export class Product {
  barCode?: string;
  extraPrice?: number;
  id = 0;
  imageDescription?: string;
  minStockQt?: number;
  name?: string;
  notParcelPrice?: number;
  offer?: boolean;
  outdatedProduct?: boolean;
  parcelPrice?: number;
  providerId?: number;
  salePrice?: number;
  simplePrice?: number;
  stockQt?: number;
  unityPrice?: number;
  unitySimplePrice?: number;
}
