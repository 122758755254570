import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-itens',
  templateUrl: './no-itens.component.html',
  styleUrls: ['./no-itens.component.scss'],
})
export class NoItensComponent {
  @Input()
  public title = '';

  constructor(private router: Router) {}

  onClickShowProducts() {
    this.router.navigate(['produtos']);
  }
}
