import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { validatePassword } from 'src/app/utils/valids';
import { LoaderService } from './../../core/services/loader/loader.service';

@Component({
  selector: 'app-forgot.page',
  templateUrl: './forgot.page.component.html',
  styleUrls: ['./forgot.page.component.scss'],
})
export class ForgotPageComponent implements OnInit, AfterViewInit {
  public token = '';
  public email = '';
  public password = '';
  public confirmPassword = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.loaderService.showLoader();
    this.activeRoute.queryParams.subscribe((params) => {
      if (params) {
        this.token = params.token;
        this.email = params.email;
      }
    });
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }

  async forgot() {
    if (this.email?.length > 6) {
      await this.loaderService.showLoader();
      try {
        await this.authService.forgot(this.email);
        this.toastr
          .success(
            'Você receberá um e-mail com as instruções para gerar uma nova senha!',
            'Senha solicitada com sucesso!'
          )
          .onTap.pipe(take(1))
          .subscribe(() => this.router.navigate(['']));
      } catch (error: any) {
        this.toastr.error(error, 'Ocorreu um erro!');
      } finally {
        await this.loaderService.hideLoader();
      }
    } else {
      this.toastr.error(
        'Por favor, informe seu e-mail corretamente!',
        'Campo obrigatório!'
      );
    }
  }

  async change() {
    const valid = validatePassword(this.password, this.confirmPassword);
    if (valid) {
      this.toastr.error(valid, 'Erro de validação!');
    } else {
      await this.loaderService.showLoader();
      try {
        await this.authService.changePassword(
          this.email,
          this.token,
          this.password
        );
        await this.loaderService.hideLoader();
        this.toastr
          .success('Sua senha foi alterada com sucesso!', 'Sucesso!')
          .onShown.pipe(take(1))
          .subscribe(() => this.router.navigate(['login']));
      } catch (error: any) {
        this.toastr.error(error, 'Ocorreu um erro!');
      } finally {
        await this.loaderService.hideLoader();
      }
    }
  }
}
