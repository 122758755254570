export class Customer {
  admin?: boolean;
  document?: string;
  email?: string;
  id?: number;
  name?: string;
  password?: string;

  fromJson(user: any) {
    if (user) {
      this.admin = user?.admin;
      this.document = user?.document;
      this.email = user?.email;
      this.id = parseFloat(user?.id);
      this.name = user?.name;
      this.password = '';
    }
  }
}
