import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { validatePassword } from 'src/app/utils/valids';
import { LoaderService } from './../../core/services/loader/loader.service';

@Component({
  selector: 'app-confirm.page',
  templateUrl: './confirm.page.component.html',
  styleUrls: ['./confirm.page.component.scss'],
})
export class ConfirmPageComponent implements OnInit, AfterViewInit {
  postData = {
    password: '',
    confirmPassword: '',
  };

  public token: any;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private loaderService: LoaderService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.loaderService.showLoader();
    this.token = this.activeRoute.snapshot.paramMap.get('token');
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }

  async confirm() {
    const valid = validatePassword(
      this.postData.password,
      this.postData.confirmPassword
    );
    if (valid) {
      this.toastr.error(valid, 'Erro de validação!');
    } else {
      await this.loaderService.showLoader();
      try {
        await this.authService.confirm(this.token, this.postData.password);
        this.toastr
          .success('Sua senha foi cadastrada!', 'Sucesso!')
          .onTap.pipe(take(1))
          .subscribe(() => this.router.navigate(['login']));
      } catch (error: any) {
        this.toastr.error(error, 'Ocorreu um erro!');
      } finally {
        await this.loaderService.hideLoader();
      }
    }
  }
}
