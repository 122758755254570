<p-carousel
  [value]="sliders"
  [numVisible]="1"
  [numScroll]="1"
  [circular]="true"
  [autoplayInterval]="5000"
  [showNavigators]="false"
>
  <ng-template let-slide pTemplate="item">
    <a
      href="{{ slide?.route }}"
      [target]="slide.route.includes('wa.me') ? '_blank' : '_self'"
    >
      <img src="assets/img/slider/{{ slide.src }}" alt="Slides" />
    </a>
  </ng-template>
</p-carousel>

<div class="lastest_arrivals">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-5">
        <h1>Produtos em destaque</h1>
      </div>

      <div class="col-12">
        <app-carousel-products
          [customer]="customer"
          [products]="products"
        ></app-carousel-products>
      </div>
    </div>
  </div>
</div>

<div class="banner-section parallax-slide" (click)="onClickBannerFooter()">
  <div class="container">
    <div class="row" *ngIf="false">
      <div class="col-12 col-lg-8 offset-lg-5 text-center">
        <div class="banner-content-wrapper">
          <span>BELEZA, CUIDADO E BEM ESTAR!</span>
          <h1>Conheça toda nossa linha</h1>
          <p>
            Vem conferir de perto nosso expositor da Nika com essas tiaras
            artesanais!
          </p>
          <button type="button" class="btn black-btn-with-border">
            SAIBA MAIS
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
