import { Customer } from './../customer';
import { CustomerDeliverItem } from './customer-deliver-item';
export class CustomerDeliver {
  createdAt?: string;
  customerUser?: Customer;
  customerUserId?: number;
  id?: number;
  isComplete?: boolean = false;
  itens?: CustomerDeliverItem[];
  sendRequestStatus?: number;
  vendorId?: number;
}
