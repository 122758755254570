<section class="contact-sec" id="contact-sec">
  <div class="container">
    <div class="row">
      <div
        class="col-12 col-lg-6 contact-description wow slideInLeft"
        data-wow-delay=".2s"
      >
        <div class="contact-detail wow fadeInLeft">
          <div class="ex-detail">
            <h4 class="large-heading">
              <span class="heading-1">Entre em </span>
              <span class="heading-2"> Contato</span>
            </h4>
          </div>
          <p class="small-text text-center text-md-left">
            Entre em contato conosco através do nosso formulário ou através de
            nosso e-mail e telefone.
          </p>
          <div class="row location-details text-center text-md-left">
            <div class="col-12 col-md-12 country-1">
              <h4 class="heading-text text-left">Endereço</h4>
              <ul>
                <li><i class="fas fa-phone"></i><a>+55 (48) 3346-0098</a></li>
                <li>
                  <i class="fab fa-whatsapp"></i
                  ><a
                    href="https://api.whatsapp.com/send?phone=5548998460319&text=Ol%C3%A1!!!"
                    target="_blank"
                    >+55 (48) 99846-0319</a
                  >
                </li>
                <li>
                  <i class="fas fa-envelope"></i
                  ><a>comercial@distribuidorasc.com.br</a>
                </li>
                <li>
                  <i class="fas fa-map-marker"></i
                  ><a>Rua Fernandes Cardoso da Silva 360 - São José - SC</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-lg-6 contact-box text-center text-md-left wow slideInRight"
        data-wow-delay=".2s"
      >
        <div class="c-box wow fadeInRight">
          <h4 class="small-heading">Deixe uma mensagem</h4>
          <form class="contact-form" id="contact-form-data">
            <div class="row my-form">
              <div class="col-md-12 col-sm-12">
                <div id="result"></div>
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="candidate_fname"
                  name="firstName"
                  placeholder="Nome"
                  required="required"
                  [(ngModel)]="postData.name"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="candidate_lname"
                  name="lastName"
                  placeholder="Telefone"
                  required="required"
                  [(ngModel)]="postData.phone"
                  mask="(00) 00000-0000"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="email"
                  class="form-control"
                  id="user_email"
                  name="userEmail"
                  placeholder="Email"
                  required="required"
                  [(ngModel)]="postData.email"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="user_subject"
                  name="userSubject"
                  placeholder="Assunto"
                  required="required"
                  [(ngModel)]="postData.subject"
                />
              </div>
              <div class="col-12">
                <textarea
                  class="form-control"
                  id="user_message"
                  name="userMessage"
                  placeholder="Mensagem"
                  rows="5"
                  required="required"
                  [(ngModel)]="postData.message"
                ></textarea>
              </div>
              <div class="col-12">
                <button
                  type="button"
                  class="btn pink-gradient-btn-into-black user-contact contact_btn"
                  type="button"
                  (click)="onClickSend()"
                >
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="map-load">
  <div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1768.6903293291373!2d-48.62761732692113!3d-27.550694309172904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952749cf9a61a52f%3A0xeaa513cb31df479d!2sDistribuidora%20Santa%20Catarina!5e0!3m2!1spt-BR!2sbr!4v1652622491832!5m2!1spt-BR!2sbr"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</div>
