<div class="section-content">
  <div class="signup-form">
    <div class="about_content">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-lg-8 offset-lg-2 text-center wow slideInUp"
            data-wow-duration="2s"
          >
            <h1 class="heading">Identifique-se</h1>
            <p class="para_text mt-3">
              Informe seu CNPJ para iniciar seu cadastro
            </p>
          </div>
        </div>
      </div>

      <div
        class="offset-lg-3 col-lg-6 col-md-12 col-sm-12 pr-lg-0 whitebox form-main"
      >
        <div class="widget logincontainer">
          <form class="getin_form border-form">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group bottom35">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="CNPJ:"
                    mask="CPF_CNPJ"
                    [(ngModel)]="document"
                  />
                </div>
              </div>

              <div class="col-sm-12">
                <button
                  (click)="signUp()"
                  class="btn pink-gradient-btn-into-black"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
