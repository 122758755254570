import { Injectable } from '@angular/core';
import { PageRequest } from 'src/app/shared/models/page/page-request';
import { PageResponse } from 'src/app/shared/models/page/page-response';
import { Customer } from './../../../shared/models/customer/customer';
import { CustomerDeliver } from './../../../shared/models/customer/deliver/customer-deliver';
import { CustomerDeliverItem } from './../../../shared/models/customer/deliver/customer-deliver-item';
import { ProductCart } from './../../../shared/models/products/product-cart';
import { HttpService } from './../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private httpService: HttpService) {}

  createDelivery(products: ProductCart[]): Promise<any> {
    return new Promise((resolve, reject): void => {
      this.httpService
        .post('customer-area/delivery/', {
          paymentMethodId: 8,
          paymentTermId: 21,
          products: products.map((item) => {
            return { productId: item.product?.id, quantity: item.quantity };
          }),
        })
        .subscribe({
          next: (_res: any) => {
            resolve(true);
          },
          error: (error) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao gerar seu pedido.';
            reject(msg);
          },
        });
    });
  }

  deliveries(itensPerPage: number, page: number = 1): Promise<PageResponse> {
    let content: PageResponse;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `customer-area/delivery/?orderBy=id&orderByDirection=desc&itensPerPage=${itensPerPage}&page=${page}`
        )
        .subscribe({
          next: (res: any) => {
            content = Object.assign(new PageResponse(), res);
            content.pageRequest = Object.assign(
              new PageRequest(),
              res.pageRequest
            );
            const deliveries: CustomerDeliver[] = [];
            content.list?.forEach((delivery) => {
              const newDelivery: CustomerDeliver = Object.assign(
                new CustomerDeliver(),
                delivery
              );
              newDelivery.itens = delivery.CustomerDeliverItem.map(
                (item: any) => {
                  item.currentPrice = item.currentPrice / 100;
                  return Object.assign(new CustomerDeliverItem(), item);
                }
              );
              newDelivery.vendorId = Number(delivery.vendorId);
              deliveries.push(newDelivery);
            });
            content.list = deliveries;
            resolve(content);
          },
          error: (error: any) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os pedidos.';
            reject(msg);
          },
        });
    });
  }

  delivery(id: number | undefined): Promise<CustomerDeliver> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`customer-area/delivery/${id}`).subscribe({
        next: (res: any) => {
          const delivery: CustomerDeliver = Object.assign(
            new CustomerDeliver(),
            res
          );
          delivery.customerUser = Object.assign(
            new Customer(),
            delivery.customerUser
          );
          delivery.vendorId = Number(res.vendorId);
          delivery.isComplete = true;
          delivery.itens = res.CustomerDeliverItem.map((item: any) => {
            item.currentPrice = item.currentPrice / 100;
            return Object.assign(new CustomerDeliverItem(), item);
          });
          resolve(delivery);
        },
        error: (error: any) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar o fornecedor.';
          reject(msg);
        },
      });
    });
  }

  synchronize(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`jobs/${token}`).subscribe({
        next: (res: any) => {
          if (res) {
            resolve(true);
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao sincronizar os dados do sistema.';
          reject(msg);
        },
      });
    });
  }
}
