import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../storage/session/session-storage.service';
import { CartService } from './../cart/cart.service';
import { TokenService } from './../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any;
  public apiUrl;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private sessionStorageService: SessionStorageService,
    public router: Router,
    private cartService: CartService
  ) {
    this.apiUrl = environment.apiUrl;
    this.tokenService.token$.subscribe((res: any) => {
      this.token = res;
    });
  }

  post(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .post(
        this.apiUrl + serviceName,
        data ? JSON.stringify(data) : null,
        options
      )
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  put(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .put(this.apiUrl + serviceName, JSON.stringify(data), options)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  get(serviceName: string, params?: HttpParams) {
    const options = {
      headers: this.getHeader(),
      withCredintials: false,
      params,
    };
    return this.http
      .get(this.apiUrl + serviceName, options)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.sessionStorageService.logout();
      this.tokenService.token$.next('');
      this.cartService.countProductsCart$.next(0);
      this.router.navigate(['']);
    }
    return throwError(() => error);
  }

  getHeader() {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json')
      .set('Accept-Encondig', 'gzip, deflate, br')
      .set('Authorization', this.token || '');
  }
}
