import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerAreaGuard } from './core/guards/customer-area/customer-area.guard';
import { LoginGuard } from './core/guards/login/login.guard';
import { TokenResolver } from './core/resolvers/token/token.resolver';
import { AboutComponent } from './pages/about/about.page.component';
import { BrandsComponent } from './pages/brands/brands.page.component';
import { CartPageComponent } from './pages/cart/cart.page.component';
import { ConfirmPageComponent } from './pages/confirm/confirm.page.component';
import { ContactComponent } from './pages/contact/contact.page.component';
import { CookiesPageComponent } from './pages/cookies/cookies.page.component';
import { CustomerAreaPageComponent } from './pages/customer-area/customer-area-page.component';
import { FirstAccessPageComponent } from './pages/first-access/first-access.page.component';
import { ForgotPageComponent } from './pages/forgot/forgot.page.component';
import { HomeComponent } from './pages/home/home.page.component';
import { LoginPageComponent } from './pages/login/login.page.component';
import { PrivacyPageComponent } from './pages/privacy/privacy.page.component';
import { ProductDetailsPageComponent } from './pages/products/details/product-details.page.component';
import { ProductsComponent } from './pages/products/products.page.component';
import { TermsPageComponent } from './pages/terms/terms.page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {
      token: TokenResolver,
    },
  },
  {
    path: 'carrinho',
    component: CartPageComponent,
    resolve: {
      token: TokenResolver,
    },
  },
  {
    path: 'contato',
    component: ContactComponent,
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    component: LoginPageComponent,
  },
  {
    path: 'marcas',
    component: BrandsComponent,
    resolve: {
      token: TokenResolver,
    },
  },
  {
    path: 'produtos',
    component: ProductsComponent,
    resolve: {
      token: TokenResolver,
    },
  },
  {
    path: 'produtos/:idProduct',
    component: ProductDetailsPageComponent,
    resolve: {
      token: TokenResolver,
    },
  },
  {
    path: 'sobre',
    component: AboutComponent,
  },
  {
    path: 'termos-e-condicoes-de-uso',
    component: TermsPageComponent,
  },
  {
    path: 'aviso-de-privacidade',
    component: PrivacyPageComponent,
  },
  {
    path: 'politica-de-cookies',
    component: CookiesPageComponent,
  },
  {
    path: 'primeiro-acesso',
    component: FirstAccessPageComponent,
  },
  {
    path: 'confirmar-cadastro/:token',
    component: ConfirmPageComponent,
  },
  {
    path: 'recuperar-senha',
    component: ForgotPageComponent,
  },
  {
    path: 'area-cliente',
    canActivate: [CustomerAreaGuard],
    component: CustomerAreaPageComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
