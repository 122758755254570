<swiper
  [spaceBetween]="20"
  [slidesPerView]="5"
  [slidesPerGroup]="1"
  [pagination]="{
    clickable: true
  }"
  [autoplay]="{
    delay: 2000,
    disableOnInteraction: false
  }"
  [breakpoints]="{
    '320': {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 10
    },
    '768': {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 20
    },
    '1024': {
      slidesPerView: 5,
      slidesPerGroup: 1,
      spaceBetween: 20
    }
  }"
  class="carousel-products"
>
  <ng-template swiperSlide *ngFor="let product of products">
    <a href="/produtos/{{ product.id }}" class="lastest_arrival_items item">
      <div>
        <a class="lastest-addto-cart"><i class="fa fa-shopping-cart"></i></a>
        <div class="card">
          <div class="image-holder">
            <img
              src="{{ linkImage + product.id + '.jpg' }}"
              class="card-img-top"
              alt="Images"
              (error)="onImgErro($event)"
            />
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center" *ngIf="customer?.id">
                <p class="card-text">
                  {{ product.salePrice || 0 | currency }}
                </p>
              </div>
              <div class="col-12 text-center">
                <h6 class="card-text">Código: {{ product.id }}</h6>
              </div>
              <div class="col-12 text-center">
                <h6 class="card-text">Cód. Barras: {{ product.barCode }}</h6>
              </div>
              <div class="col-12 text-center">
                <h5 class="card-title">{{ product.name }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </ng-template>
</swiper>
