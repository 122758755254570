<a class="scroll-top-arrow" href="javascript:void(0);"
  ><i class="fa fa-angle-up"></i
></a>
<div class="loaderMain">
  <div class="loader-inner">
    <div id="preloader">
      <div id="loader"></div>
    </div>
  </div>
</div>

<header class="header-area">
  <div class="container">
    <div class="row upper-nav">
      <div class="col-3 nav-icon d-flex align-items-center">
        <ul class="social-icons-simple text-left">
          <li>
            <a
              href="https://www.facebook.com/mktdistribuidorasc/"
              target="_blank"
              class="facebook-bg-hvr"
              ><i class="fab fa-facebook-f" aria-hidden="true"></i
            ></a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=5548998460319&text=Ol%C3%A1!!!"
              target="_blank"
              class="whatsapp-bg-hvr"
              ><i class="fab fa-whatsapp" aria-hidden="true"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/distribuidora_santacatarina/"
              target="_blank"
              class="instagram-bg-hvr"
              ><i class="fab fa-instagram" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="col-6 text-center nav-logo">
        <a routerLink="/" class="navbar-brand"
          ><img src="assets/img/logo/logo.png" alt="img"
        /></a>
      </div>

      <div
        class="col-3 nav-utility text-right d-flex justify-content-end align-items-center"
      >
        <div class="manage-icons d-inline-block">
          <ul>
            <li class="d-inline-block">
              <a id="add_search_box">
                <i class="fas fa-search search-sidebar-hover"></i>
              </a>
            </li>
            <li class="d-inline-block mini-menu-card">
              <a class="nav-link" routerLink="/carrinho">
                <i class="fas fa-shopping-cart"
                  ><span *ngIf="countProductsCart > 0">{{
                    countProductsCart
                  }}</span></i
                >
              </a>
            </li>
            <li class="d-inline-block mini-menu-card">
              <a class="nav-link" routerLink="/login">
                <i class="fas fa-user"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 nav-mega">
        <header class="site-header" id="header">
          <nav class="navbar navbar-expand-md static-nav">
            <div class="container position-relative megamenu-custom">
              <a class="navbar-brand center-brand" routerLink="/">
                <img
                  src="assets/img/logo/logo.png"
                  alt="logo"
                  class="logo-scrolled"
                />
              </a>
              <div class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto mr-auto">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/' }"
                      routerLink="/"
                      >HOME</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/sobre' }"
                      routerLink="/sobre"
                      >QUEM SOMOS</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/marcas' }"
                      routerLink="/marcas"
                      >MARCAS</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/produtos' }"
                      routerLink="/produtos"
                      >PRODUTOS</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/contato' }"
                      routerLink="/contato"
                      >CONTATO</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <a
              href="javascript:void(0)"
              class="d-inline-block sidemenu_btn d-lg-none d-md-block"
              id="sidemenu_toggle"
            >
              <span></span> <span></span> <span></span>
            </a>
          </nav>

          <div class="side-menu opacity-0 gradient-bg hidden">
            <div class="inner-wrapper">
              <span class="btn-close btn-close-no-padding" id="btn_sideNavClose"
                ><i></i><i></i
              ></span>
              <nav class="side-nav w-100">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/' }"
                      routerLink="/"
                      >Home</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/sobre' }"
                      routerLink="/sobre"
                      >Quem Somos</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/marcas' }"
                      routerLink="/marcas"
                      >Marcas</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/produtos' }"
                      routerLink="/produtos"
                      >Produtos</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/contato' }"
                      routerLink="/contato"
                      >Contato</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/area-cliente' }"
                      routerLink="/area-cliente"
                      >Pedidos</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: route === '/carrinho' }"
                      routerLink="/carrinho"
                      >Carrinho</a
                    >
                  </li>
                </ul>
              </nav>
              <div class="side-footer w-100">
                <ul class="social-icons-simple white top40">
                  <li>
                    <a
                      class="facebook-bg-hvr"
                      href="https://www.facebook.com/mktdistribuidorasc/"
                      target="_blank"
                      ><i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      class="whatsapp-bg-hvr"
                      href="https://api.whatsapp.com/send?phone=5548998460319&text=Ol%C3%A1!!!"
                      target="_blank"
                      ><i class="fab fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      class="instagram-bg-hvr"
                      href="https://www.instagram.com/distribuidora_santacatarina/"
                      target="_blank"
                      ><i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
                <p class="whitecolor">
                  &copy; 2022 Todos Os Direitos Reservados. Distribuidora Santa
                  Catarina
                </p>
              </div>
            </div>
          </div>
          <div id="close_side_menu" class="tooltip"></div>
        </header>
      </div>
    </div>
  </div>
</header>

<div class="search-box-overlay">
  <a><i class="fas fa-times cross-sign" id="close-window"></i></a>
  <div class="container">
    <div class="row">
      <div class="col-12 search-col">
        <form action="#">
          <div class="input-group search-box-form">
            <input
              id="input_search_box"
              type="text"
              class="form-control"
              placeholder="Código, cod. barra ou nome"
              aria-label="Código, cod. barra ou nome"
              [(ngModel)]="keyword"
            />
            <div class="input-group-prepend">
              <button
                (click)="onClickKeyword()"
                class="input-group-text"
                type="submit"
                id="basic-addon1"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
