import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoaderService } from './../../core/services/loader/loader.service';

@Component({
  selector: 'app-about-page',
  templateUrl: './about.page.component.html',
  styleUrls: ['./about.page.component.scss'],
})
export class AboutComponent implements OnInit, AfterViewInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }
}
