<footer class="footer">
  <div class="container">
    <div class="row footer-container">
      <div class="col-sm-12 col-lg-5 f-sec1 text-center text-lg-left">
        <h4 class="high-lighted-heading">Sobre Nós</h4>
        <p>
          Conheça-nos melhor. Saiba mais sobre o nosso trabalho, nossa missão,
          nossa visão e nossos valores.
        </p>
        <h4>Redes Sociais</h4>
        <div class="s-icons">
          <ul class="social-icons-simple">
            <li>
              <a
                href="https://www.facebook.com/mktdistribuidorasc/"
                target="_blank"
                class="facebook-bg-hvr"
                ><i class="fab fa-facebook-f" aria-hidden="true"></i
              ></a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=554898460319&text=Ol%C3%A1!!!"
                target="_blank"
                class="whatsapp-bg-hvr"
                ><i class="fab fa-whatsapp" aria-hidden="true"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/distribuidora_santacatarina/"
                target="_blank"
                class="instagram-bg-hvr"
                ><i class="fab fa-instagram" aria-hidden="true"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-12 col-lg-3 f-sec1 text-center text-lg-left">
        <h4 class="high-lighted-heading">Informações</h4>
        <ul class="text-center text-md-left">
          <li><a routerLink="/sobre">Quem Somos</a></li>
          <li>
            <a routerLink="/termos-e-condicoes-de-uso"
              >Termos e Condições de Uso</a
            >
          </li>
          <li>
            <a routerLink="/aviso-de-privacidade">Aviso de Privacidade</a>
          </li>
          <li>
            <a routerLink="/politica-de-cookies">Política de Cookies</a>
          </li>
          <li><a routerLink="/contato">Contato</a></li>
        </ul>
      </div>
      <div
        class="col-sm-12 col-lg-2 f-sec1 text-center text-lg-left mobile-invisible"
      >
        <h4 class="text-center text-md-left high-lighted-heading">Produtos</h4>
        <ul class="text-center text-md-left">
          <li><a routerLink="/marcas">Marcas</a></li>
          <li><a routerLink="/produtos">Produtos</a></li>
        </ul>
      </div>
      <div
        class="col-sm-12 col-lg-2 f-sec1 text-center text-lg-left mobile-invisible"
      >
        <h4 class="text-center text-md-left high-lighted-heading">
          Minha Conta
        </h4>
        <ul class="text-center text-md-left">
          <li><a routerLink="/login">Login</a></li>
          <li><a routerLink="/carrinho">Carrinho</a></li>
          <li><a routerLink="/area-cliente">Pedidos</a></li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 footer_notes">
        <p class="whitecolor text-center w-100 wow fadeInDown">
          &copy; 2022 Todos Os Direitos Reservados. Distribuidora Santa Catarina
        </p>
      </div>
    </div>
  </div>
</footer>
