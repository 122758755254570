<div class="content">
  <app-no-itens
    *ngIf="!haveDeliveries()"
    [title]="'VOCÊ NÃO POSSUI PEDIDOS!'"
  ></app-no-itens>
  <div *ngIf="haveDeliveries()">
    <h3>Meus Pedidos</h3>
    <p-table
      [value]="deliveries"
      dataKey="id"
      [rowExpandMode]="'single'"
      (onRowExpand)="onRowExpand($event)"
      #table
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%">Pedido</th>
          <th style="width: 20%">Data</th>
          <th
            class="mobile-invisible"
            style="width: 100%"
            *ngIf="customer.admin"
          >
            Cliente
          </th>
          <th style="width: 20%">Valor</th>
          <th>Status</th>
          <th style="width: 5%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-deliver let-expanded="expanded">
        <tr class="tbody-tr" (click)="table.toggleRow(deliver, $event)">
          <td>{{ deliver?.vendorId || "####" }}</td>
          <td class="mobile-invisible">
            {{ deliver?.createdAt | date : "short" }}
          </td>
          <td class="mobile-visible">
            {{ deliver?.createdAt | date : "shortDate" }}
          </td>
          <td class="mobile-invisible" *ngIf="customer?.admin">
            {{ deliver?.customerUser?.name }}
          </td>
          <td>{{ getTotalValue(deliver?.itens) | currency }}</td>
          <td>{{ deliver?.vendorId ? "Realizado" : "Erro" }}</td>
          <td>
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="deliver"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              [pRowTogglerDisabled]="true"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-product>
        <tr>
          <td colspan="6">
            <div>
              <p-table
                class="table-products"
                [value]="product?.itens"
                dataKey="id"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Produto</th>
                    <th class="mobile-invisible" style="width: 10%">Preço</th>
                    <th class="mobile-invisible th-quantity" style="width: 10%">
                      Quantidade
                    </th>
                    <th class="mobile-invisible" style="width: 10%">Total</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>
                      <div class="td-product">
                        <img
                          [src]="linkImage + item?.productId + '.jpg'"
                          [alt]="item?.product?.name"
                          width="80"
                          class="shadow-4"
                        />
                        <div class="td-product-text">
                          <h4>
                            <a routerLink="/produtos/{{ item?.product?.id }}">{{
                              item?.product?.name
                            }}</a>
                          </h4>
                        </div>
                      </div>
                      <div class="td-product-mobile mobile-visible">
                        <h4>Valor: {{ item?.currentPrice | currency }}</h4>
                        <h4>Qtd: {{ item?.quantity }}</h4>
                        <h4>
                          Total:
                          {{ item?.quantity * item?.currentPrice | currency }}
                        </h4>
                      </div>
                    </td>
                    <td class="mobile-invisible">
                      {{ item?.currentPrice | currency }}
                    </td>
                    <td class="mobile-invisible">{{ item?.quantity }}</td>
                    <td class="mobile-invisible">
                      {{ item?.quantity * item?.currentPrice | currency }}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  Valor total do pedido:
                  {{ getTotalValue(product?.itens) | currency }}
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="6">Esse pedido não possui produtos!</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-paginator
    [rows]="10"
    [totalRecords]="pageResponseDeliveries.totalRows || 0"
    (onPageChange)="onPageChange($event)"
  ></p-paginator>
</div>
