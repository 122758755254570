import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthConstants } from 'src/app/core/config/auth-constants';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { SessionStorageService } from 'src/app/core/services/storage/session/session-storage.service';
import { PageResponse } from 'src/app/shared/models/page/page-response';
import { Product } from 'src/app/shared/models/products/product';
import { ProductFilter } from 'src/app/shared/models/products/product-filter';
import { environment } from 'src/environments/environment';
import { CartService } from './../../../core/services/cart/cart.service';
import { LoaderService } from './../../../core/services/loader/loader.service';
import { ProvidersService } from './../../../core/services/providers/providers.service';
import { Customer } from './../../../shared/models/customer/customer';
import { Provider } from './../../../shared/models/providers/provider';

@Component({
  selector: 'app-product-details.page',
  templateUrl: './product-details.page.component.html',
  styleUrls: ['./product-details.page.component.scss'],
})
export class ProductDetailsPageComponent implements OnInit {
  public pageResponseProduct: PageResponse = new PageResponse();
  public productsMore: Product[] = [];
  public product: Product = new Product();
  public provider: Provider = new Provider();
  public customer: Customer = new Customer();
  public quantity = 1;
  public linkImage: string = environment.linkImages;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private providersService: ProvidersService,
    private sessionStorageService: SessionStorageService,
    private loaderService: LoaderService,
    private cartService: CartService,
    private toastr: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.getUser();
  }

  getUser() {
    this.sessionStorageService.get(AuthConstants.AUTH).then((res) => {
      this.customer = new Customer();
      if (res) {
        this.customer.fromJson(res);
      }
    });
  }

  ngOnInit(): void {
    const id = this.activeRoute.snapshot.paramMap.get('idProduct') || '';
    this.getProduct(id);
  }

  async getProduct(id: string) {
    await this.loaderService.showLoader();
    try {
      this.product = await this.productsService.product(id);
      this.getProvider();
      this.getProductsMore();
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  async getProvider() {
    await this.loaderService.showLoader();
    try {
      this.provider = await this.providersService.provider(
        this.product.providerId
      );
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  async getProductsMore() {
    this.productsMore = [];
    await this.loaderService.showLoader();
    try {
      const provider: Provider = new Provider();
      provider.id = this.product.providerId;
      const filter: ProductFilter = new ProductFilter();
      filter.providers = [provider];
      this.pageResponseProduct = await this.productsService.products(
        filter,
        20
      );
      if (this.pageResponseProduct) {
        this.productsMore = this.pageResponseProduct.list as Product[];
      }
    } catch (error) {
      console.log(error);
    } finally {
      await this.loaderService.hideLoader();
      setTimeout(() => {
        window.dispatchEvent(new Event('refreshOwlCarouselMore'));
      }, 500);
    }
  }

  onClickAddToCart() {
    if (this.customer?.id) {
      this.addToCart();
    } else {
      this.toastr.error(
        'Você precisa efetuar o seu login para adicionar produtos ao carrinho!',
        'Usuário não logado!'
      );
    }
  }

  async addToCart() {
    await this.loaderService.showLoader();
    try {
      await this.cartService.addToCart({
        product: this.product,
        quantity: this.quantity,
      });
      this.toastr.success(
        'Produto adicionado ao carrinho com sucesso!',
        'Sucesso!'
      );
    } catch (error) {
      console.log(error);
      this.toastr.error(
        'Ocorreu um erro ao adicionar o produto no carrinho!',
        'Erro!'
      );
    } finally {
      await this.loaderService.hideLoader();
      setTimeout(() => {
        window.dispatchEvent(new Event('refreshOwlCarouselMore'));
      }, 500);
    }
  }

  onClickMinus() {
    if (this.quantity === 1) {
      this.quantity = 1;
    } else {
      this.quantity = this.quantity - 1;
    }
  }

  onClickMore() {
    setTimeout(() => {
      this.quantity = this.quantity + 1;
    }, 100);
  }

  onClickContinueShop() {
    this.router.navigateByUrl(`produtos?idProvider=${this.provider.id}`);
  }

  onImgErro(event: any) {
    event.target.src = 'assets/img/products/no-image.jpg';
  }

  onClickProvider() {
    this.router.navigateByUrl(`produtos?idProvider=${this.provider.id}`);
  }
}
