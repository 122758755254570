import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoaderService } from './../../core/services/loader/loader.service';

@Component({
  selector: 'app-first-access.page',
  templateUrl: './first-access.page.component.html',
  styleUrls: ['./first-access.page.component.scss'],
})
export class FirstAccessPageComponent implements OnInit, AfterViewInit {
  public document = '';

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }

  async signUp() {
    if (this.document?.trim()?.length > 13) {
      await this.loaderService.showLoader();
      try {
        await this.authService.signUp(this.document);
      } catch (error: any) {
        if (error === 'Parceiro não encontrado!') {
          this.toastr.warning(
            `Entre em contato para realizar o seu cadastro!
            <a href="https://api.whatsapp.com/send?phone=5548998460319&text=Ol%C3%A1!%20Gostaria%20de%20realizar%20o%20meu%20cadastro!" target="_blank" class="whatsapp-bg-hvr">
            <i class="fab fa-whatsapp" aria-hidden="true">
            </i>
            </a>`,
            'Parceiro não encontrado!',
            {
              timeOut: 6000,
              extendedTimeOut: 3000,
            }
          );
        } else {
          this.toastr.error(error, 'Ocorreu um erro!');
        }
      } finally {
        await this.loaderService.hideLoader();
      }
    } else {
      this.toastr.error(
        'Por favor, informe seu CNPJ corretamente!',
        'Campo obrigatório!'
      );
    }
  }
}
