import { Provider } from './../providers/provider';
export class ProductFilter {
  barCode?: string;
  id?: number;
  name?: string;
  offer?: boolean;
  providers: Provider[] = [];

  getUrl(): String {
    let url = '';

    if (this.barCode) {
      url = `barCode=${this.barCode}&`;
    }

    if (this.id) {
      url = `id=${this.id}&`;
    }

    if (this.name) {
      url = `name=${this.name}&`;
    }

    if (this.offer) {
      url = `offer=${this.offer}&`;
    }

    if (this.providers.length > 0) {
      const idsProviders = ``;

      if (this.providers.length === 1) {
        url = `providerId=${this.providers[0].id}&`;
      } else {
        url = `providerId=${this.providers.map((prov) => prov.id).join(',')}&`;
      }
    }
    return url;
  }
}
