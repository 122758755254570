import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, take } from 'rxjs';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { HttpService } from '../http/http.service';
import { SessionStorageService } from '../storage/session/session-storage.service';
import { TokenService } from '../token/token.service';
import { Customer } from './../../../shared/models/customer/customer';
import { AuthConstants } from './../../config/auth-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData$ = new BehaviorSubject<any>('');

  constructor(
    private httpService: HttpService,
    private sessionStorageService: SessionStorageService,
    private tokenService: TokenService,
    private cartService: CartService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  getUserData() {
    this.sessionStorageService.get(AuthConstants.AUTH).then((res) => {
      this.userData$.next(res);
    });
  }

  login(document: string, password: string): Promise<any> {
    const customer: Customer = new Customer();
    return new Promise((resolve, reject) => {
      this.httpService.post('auth', { document, password }).subscribe({
        next: (res: any) => {
          if (res.user) {
            res.user.admin = res.admin;
            customer.fromJson(res.user);
            this.sessionStorageService.set(AuthConstants.AUTH, customer);
            this.sessionStorageService.set(AuthConstants.TOKEN, res.token);
            this.cartService.getCountProducts();
            this.router.navigate(['area-cliente']);
            resolve(customer);
          } else {
            reject('Senha incorreta.');
          }
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao tentar efetuar o login!';
          reject(msg);
        },
      });
    });
  }

  signUp(document: string): Promise<any> {
    return new Promise((resolve, reject): void => {
      this.httpService.post('sign-up', { document }).subscribe({
        next: (_res: any) => {
          this.toastr
            .success(
              'Em seguida nossa equipe entrará em contato para liberar o seu acesso.',
              'Usuário cadastrado com sucesso!'
            )
            .onTap.pipe(take(1))
            .subscribe(() => this.router.navigate(['login']));
          resolve(true);
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao tentar cadastrar seu usuário.';
          reject(msg);
        },
      });
    });
  }

  confirm(token: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`change-password/${token}`, { password })
        .subscribe({
          next: (res: any) => {
            if (res?.ok) {
              resolve(true);
            } else {
              reject();
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao validar sua conta.';
            reject(msg);
          },
        });
    });
  }

  logout() {
    this.sessionStorageService.logout().then((_res) => {
      this.userData$.next('');
      this.tokenService.token$.next('');
      this.cartService.countProductsCart$.next(0);
      this.router.navigate(['login']);
    });
  }

  forgot(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`password-recovery/${email}/`).subscribe({
        next: (res: any) => {
          if (res?.ok) {
            resolve(true);
          } else {
            reject();
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao tentar recuperar sua senha.';
          reject(msg);
        },
      });
    });
  }

  changePassword(email: string, token: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`password-recovery/${email}/`, { password, token })
        .subscribe({
          next: (res: any) => {
            if (res?.ok) {
              resolve(true);
            } else {
              reject();
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao cadastrar nova senha!';
            reject(msg);
          },
        });
    });
  }
}
