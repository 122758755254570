import { Injectable } from '@angular/core';
import { PageRequest } from 'src/app/shared/models/page/page-request';
import { PageResponse } from 'src/app/shared/models/page/page-response';
import { Provider } from './../../../shared/models/providers/provider';
import { HttpService } from './../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProvidersService {
  constructor(private httpService: HttpService) {}

  providers(): Promise<PageResponse> {
    let content: PageResponse;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`providers/?orderBy=name&orderByDirection=asc&itensPerPage=1000`)
        .subscribe({
          next: (res: any) => {
            content = Object.assign(new PageResponse(), res);
            content.pageRequest = Object.assign(
              new PageRequest(),
              res.pageRequest
            );
            const providers: Provider[] = [];
            content.list?.forEach((provider) => {
              if (
                provider?.name &&
                provider?.name !== '0' &&
                provider?.productsCount > 0
              ) {
                provider.name = provider.name.toLowerCase();
                providers.push(Object.assign(new Provider(), provider));
              }
            });
            content.list = providers;
            resolve(content);
          },
          error: (error: any) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os fornecedores.';
            reject(msg);
          },
        });
    });
  }

  provider(id: number | undefined): Promise<Provider> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`providers/${id}`).subscribe({
        next: (res: any) => {
          resolve(Object.assign(new PageResponse(), res));
        },
        error: (error: any) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar o fornecedor.';
          reject(msg);
        },
      });
    });
  }
}
