import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor() {}

  async showLoader() {
    const loader = (window as any).curentLoaderMain;
    if (loader) {
      loader[0].classList.remove('loaderMain-hidden');
      loader[0].classList.add('loaderMain-show');
    }
  }

  async hideLoader() {
    const loader = (window as any).curentLoaderMain;
    if (loader) {
      setTimeout(() => {
        loader[0].classList.remove('loaderMain-show');
        loader[0].classList.add('loaderMain-hidden');
      }, 500);
    }
  }
}
