<div class="section-content">
  <div class="signup-form">
    <div class="about_content">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-lg-8 offset-lg-2 text-center wow slideInUp"
            data-wow-duration="2s"
          >
            <h1 class="heading">
              {{ token ? "Nova Senha" : "Informe seu e-mail" }}
            </h1>
            <p class="para_text mt-3">
              {{
                token
                  ? "Informe uma nova senha e sua confirmação"
                  : "Você receberá um link com informações para recuperação de senha"
              }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="offset-lg-3 col-lg-6 col-md-12 col-sm-12 pr-lg-0 whitebox form-main"
      >
        <div class="widget logincontainer" *ngIf="!token">
          <form class="getin_form border-form">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group bottom35">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="E-mail:"
                    required=""
                    [(ngModel)]="email"
                  />
                </div>
              </div>

              <div class="col-sm-12 form-main-btn">
                <button
                  (click)="forgot()"
                  class="btn pink-gradient-btn-into-black"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="widget logincontainer" *ngIf="token">
          <form class="getin_form border-form">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group bottom35">
                  <input
                    class="form-control"
                    type="password"
                    placeholder="Senha:"
                    required=""
                    [(ngModel)]="password"
                  />
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group bottom35">
                  <input
                    class="form-control"
                    type="password"
                    placeholder="Confirme a Senha:"
                    required=""
                    [(ngModel)]="confirmPassword"
                  />
                </div>
              </div>

              <div class="col-sm-12 form-main-btn">
                <button
                  (click)="change()"
                  class="btn pink-gradient-btn-into-black"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
