import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs';
import { AuthConstants } from 'src/app/core/config/auth-constants';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { SessionStorageService } from 'src/app/core/services/storage/session/session-storage.service';
import { AuthService } from './../../core/services/auth/auth.service';
import { TokenService } from './../../core/services/token/token.service';
import { Customer } from './../../shared/models/customer/customer';

@Component({
  selector: 'app-customer-area-page',
  templateUrl: './customer-area-page.component.html',
  styleUrls: ['./customer-area-page.component.scss'],
})
export class CustomerAreaPageComponent implements OnInit {
  public customer: Customer = new Customer();
  public token: string = '';

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private customerService: CustomerService,
    private loaderService: LoaderService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private toastr: ToastrService,
    private tokenService: TokenService
  ) {
    this.tokenService.getToken();
  }
  ngOnInit(): void {
    this.tokenService.token$.subscribe((res: any) => {
      if (res) {
        this.token = res;
        this.getUser();
      }
    });
  }

  getUser() {
    this.sessionStorageService.get(AuthConstants.AUTH).then((res) => {
      this.customer = new Customer();
      if (res) {
        this.customer.fromJson(res);
      }
    });
  }

  onClickChangePassword() {
    this.confirmationService.confirm({
      message: 'Deseja realmente alterar a sua senha?',
      header: 'Confirmação',
      accept: () => {
        this.changePassword();
      },
    });
  }

  async changePassword() {
    await this.loaderService.showLoader();
    try {
      if (this.customer.email) {
        await this.authService.forgot(this.customer.email);
        await this.loaderService.hideLoader();
        this.toastr
          .success(
            'Você receberá um e-mail com as instruções para alterar sua senha!',
            'Alteração de senha!'
          )
          .onHidden.pipe(take(1))
          .subscribe(() => this.logout());
      }
    } catch (error: any) {
      this.toastr.error(error, 'Ocorreu um erro!');
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  onClickLogout() {
    this.confirmationService.confirm({
      message: 'Deseja realmente sair?',
      header: 'Confirmação',
      accept: () => {
        this.logout();
      },
    });
  }

  onClickSynchronize() {
    this.confirmationService.confirm({
      message: 'Deseja realmente sincronizar os dados do sistema?',
      header: 'Confirmação',
      accept: () => {
        this.synchronize();
      },
    });
  }

  async synchronize() {
    await this.loaderService.showLoader();
    try {
      if (this.token) {
        await this.customerService.synchronize(this.token);
        await this.loaderService.hideLoader();
        this.toastr.success(
          'Todos os dados foram sincronizados com sucesso!',
          'Sucesso!'
        );
      } else {
        this.toastr.error(
          'Você não possui permissão para sincronizar os dados do sistema.',
          'Ocorreu um erro!'
        );
      }
    } catch (error: any) {
      this.toastr.error(error, 'Ocorreu um erro!');
    } finally {
      await this.loaderService.hideLoader();
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
