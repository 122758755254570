<div class="section-header">
  <h1>Termos e Condições de Uso</h1>
</div>

<div class="terms section-content">
  <div class="container">
    <p>Olá! Estamos muito felizes em receber você no nosso espaço online!</p>
    <p>
      Existem algumas regras de funcionamento da nossa plataforma que queremos
      deixar claras para você, facilitando o seu uso.
    </p>
    <p>
      No nosso site você encontrará todos os produtos fabricados por nossos
      parceiros e que fazem parte do nosso catálogo de vendas com os seus
      respectivos preços, por meio de uma plataforma de e-commerce.
    </p>
    <p>
      Ao acessar o nosso site e fazer uso da plataforma, você concorda com os
      presentes Termos de Uso, a Política de Privacidade e a Política de
      Cookies.
    </p>

    <h2>PADRÃO DE COMPRA</h2>
    <p>
      Você irá se cadastrar no nosso site definindo um login e senha de acesso,
      esta informação é compartilhada entre a empresa responsável pela
      plataforma e a nossa operação interna.
    </p>
    <p>
      Para efetuar o cadastro, você deve ser uma pessoa maior de 18 anos ou uma
      pessoa jurídica, cadastrar um e-mail de login e uma senha. Orientamos pela
      escolha de uma senha segura, com letras maiúsculas e minúsculas, números e
      caracteres especiais.
    </p>
    <p>
      Caso você esqueça da sua senha, poderá definir outra pelo ícone “redefinir
      senha”.
    </p>
    <p>
      Após o cadastro, você poderá selecionar os produtos que deseja por meio de
      um orçamento, o qual é encaminhado aos nossos vendedores.
    </p>
    <p>
      Um dos nossos vendedores recebe o seu pedido e entrará em contato
      diretamente com você para finalizar a compra, definindo o modo de
      pagamento, de entrega e demais condições.
    </p>
    <p>
      Após a conclusão da compra com o nosso atendente e a confirmação do
      pagamento, você receberá o produto no endereço definido, de modo prático e
      seguro.
    </p>
    <p>
      Não ficam armazenadas informações no site além do login e senha de acesso,
      sendo compartilhado com o nosso vendedor apenas o login cadastrado a fim
      de identificar o cliente e efetuar o contato.
    </p>
    <p>
      A Santa Catarina Distribuidora preserva-se no direito de negar alguma
      tentativa cadastro sem necessidade de justificativa ao cliente.
    </p>

    <h2>MODALIDADES DE ENTREGA E DEVOLUÇÃO DE PRODUTOS</h2>
    <p>
      A modalidade de entrega será definida diretamente com o colaborador da
      empresa, cabendo o aceite pelo cliente.
    </p>

    <p>
      No momento em que receber os produtos, o cliente deve conferir se todos os
      itens estão corretos e em estado adequado para o uso a que se destinam,
      possuindo o prazo de 7 dias para reclamar de eventuais defeitos e realizar
      o pedido de troca.
    </p>
    <p>
      Na hipótese de ser verificado algum defeito, o cliente deve entrar em
      contato com a empresa por meio do e-mail ou do telefone e solicitar a
      substituição, seguindo o passo a passo do colaborador da empresa.
    </p>
    <p>
      Caso ainda haja alguma dúvida, poderá entrar em contato com a empresa para
      esclarecimentos.
    </p>

    <h2>PROIBIÇÕES</h2>
    <p>Não é permitido mais de um login por cliente cadastrado.</p>
    <p>Não é permitida a venda para menores de 18 anos.</p>
    <p>
      Os dados cadastrais devem ser verdadeiros, sendo vedado também ocultação
      de informações.
    </p>
    <p>
      É vedado o compartilhamento de informações de login e senha com terceiros.
    </p>

    <h2>SERVIÇOS DISPONÍVEIS</h2>
    <p>
      Você encontrará no nosso site, além da modalidade de compra e venda citada
      no tópico anterior: (I) os canais de contato com a empresa; (II) o acesso
      à nossa Política de Privacidade, Política de Cookies e o presente Termos
      de Uso; (III) a nossa Política de Trocas e Devoluções com o seu respectivo
      canal.
    </p>

    <h2>RELAÇÃO COM TERCEIROS</h2>
    <p>
      A Santa Catarina poderá apresentar links de outros sites ou plataformas,
      não podendo ser responsabilizada pelo funcionamento destas ou pelas suas
      políticas internas, cabendo ao usuário avaliar a sua confiabilidade ou
      não.
    </p>

    <h2>PAGAMENTOS</h2>
    <p>
      Todos os pagamentos são feitos diretamente com a empresa, não havendo
      modalidades de pagamento por meio da presente plataforma.
    </p>

    <h2>CANCELAMENTO OU EXCLUSÃO</h2>
    <p>
      O uso da presente plataforma deve respeitar os parâmetros definidos pela
      Santa Catarina Distribuidora, possuindo esta autonomia para excluir
      qualquer cadastro realizado no site sem necessidade de justificativa ou
      aviso prévio.
    </p>
    <p>
      Não há a possibilidade de responsabilização da empresa pela decisão de
      cancelamento do cadastro.
    </p>
    <p>
      O cliente também poderá solicitar a exclusão do seu cadastro no site, ou
      seja, login e senha, por meio do e-mail comercial@distribuidorasc.com.br,
      ressalvados os casos de obrigação legal.
    </p>
    <p>
      Ao cancelar o cadastro, o cliente perde os benefícios que eventualmente
      adquiriu com o cadastro anterior.
    </p>

    <h2>RESPONSABILIDADE</h2>
    <p>
      A Santa Catarina não se responsabiliza por eventual interrupção do serviço
      por razões alheias a sua vontade, tampouco garante a prestação
      ininterrupta dos seus serviços.
    </p>
    <p>
      A empresa também não se responsabiliza pelo uso indevido ou inapropriado
      da plataforma e por danos indiretos que decorram da navegação.
    </p>

    <h2>PROPRIEDADE INTELECTUAL</h2>
    <p>
      Todo o conteúdo apresentado no site é protegido por meio da propriedade
      intelectual, havendo a possibilidade de responsabilização caso verificada
      a tentativa de cópia ou fraude, conforme estabelecido em lei.
    </p>

    <h2>ALTERAÇÃO</h2>
    <p>
      Os presentes Termos poderão ser alterados a qualquer tempo pela empresa,
      cabendo a esta informar ao Titular sobre eventuais modificações.
    </p>
    <p>Este documento foi atualizado em 25 de novembro de 2022.</p>

    <p>Canais de comunicação:</p>
    <p>Santa Catarina Distribuidora</p>
    <p>comercial@distribuidorasc.com.br</p>
    <p>(48) 3346-0098</p>
  </div>
</div>
