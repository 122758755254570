import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CartService } from 'src/app/core/services/cart/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public route = '';
  public keyword = '';
  public countProductsCart = 0;

  constructor(public router: Router, private cartService: CartService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.route = val.url;
        window.dispatchEvent(new Event('onClickMenuMobile'));
      }
    });

    this.cartService.countProductsCart$.subscribe((res: any) => {
      this.countProductsCart = res || 0;
    });
    this.cartService.getCountProducts();
  }

  onClickKeyword() {
    if (this.keyword) {
      this.router.navigateByUrl(`produtos?keyword=${this.keyword}`);
      window.dispatchEvent(new Event('onCloseWindowSearchBox'));
    }
  }
}
