import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CurrencyDirective } from './core/directives/currency.directive';
import { PipesModule } from './core/pipes/pipes.module';
import { AboutComponent } from './pages/about/about.page.component';
import { BrandsComponent } from './pages/brands/brands.page.component';
import { CartPageComponent } from './pages/cart/cart.page.component';
import { CatalogsComponent } from './pages/catalogs/catalogs.page.component';
import { ConfirmPageComponent } from './pages/confirm/confirm.page.component';
import { ContactComponent } from './pages/contact/contact.page.component';
import { CookiesPageComponent } from './pages/cookies/cookies.page.component';
import { CustomerAreaPageComponent } from './pages/customer-area/customer-area-page.component';
import { FirstAccessPageComponent } from './pages/first-access/first-access.page.component';
import { ForgotPageComponent } from './pages/forgot/forgot.page.component';
import { HomeComponent } from './pages/home/home.page.component';
import { LoginPageComponent } from './pages/login/login.page.component';
import { PrivacyPageComponent } from './pages/privacy/privacy.page.component';
import { ProductDetailsPageComponent } from './pages/products/details/product-details.page.component';
import { ProductsComponent } from './pages/products/products.page.component';
import { TermsPageComponent } from './pages/terms/terms.page.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { DeliveriesComponent } from './shared/components/deliveries/deliveries.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { NoItensComponent } from './shared/components/no-itens/no-itens.component';
import { CarouselProductsComponent } from './shared/components/carousel-products/carousel-products.component';
registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    BrandsComponent,
    ProductsComponent,
    CatalogsComponent,
    ContactComponent,
    CurrencyDirective,
    ProductDetailsPageComponent,
    CartPageComponent,
    LoginPageComponent,
    TermsPageComponent,
    PrivacyPageComponent,
    CookiesPageComponent,
    ForgotPageComponent,
    FirstAccessPageComponent,
    ConfirmPageComponent,
    CustomerAreaPageComponent,
    ConfirmDialogComponent,
    DeliveriesComponent,
    NoItensComponent,
    CarouselProductsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CarouselModule,
    ConfirmDialogModule,
    FormsModule,
    HttpClientModule,
    MessagesModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PaginatorModule,
    PipesModule,
    SwiperModule,
    TableModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true,
      timeOut: 4000,
      closeButton: true,
    }),
  ],
  providers: [
    HttpClientModule,
    provideNgxMask(),
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
