import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { CartService } from 'src/app/core/services/cart/cart.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { environment } from 'src/environments/environment';
import { CustomerService } from './../../core/services/customer/customer.service';
import { ProductCart } from './../../shared/models/products/product-cart';

@Component({
  selector: 'app-cart.page',
  templateUrl: './cart.page.component.html',
  styleUrls: ['./cart.page.component.scss'],
})
export class CartPageComponent implements OnInit, AfterViewInit {
  public productsCart: ProductCart[] = [];
  public linkImage: string = environment.linkImages;

  constructor(
    private loaderService: LoaderService,
    private cartService: CartService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private customerService: CustomerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getproductsCart();
  }

  async getproductsCart() {
    this.loaderService.showLoader();
    try {
      this.productsCart = await this.cartService.getProducts();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  onClickDeleteProduct(productCart: ProductCart) {
    this.confirmationService.confirm({
      message: 'Deseja realmente remover esse produto do carrinho?',
      header: 'Confirmação',
      accept: () => {
        this.deleteProduct(productCart);
      },
    });
  }

  async deleteProduct(productCart: ProductCart) {
    await this.loaderService.showLoader();
    try {
      await this.cartService.deleteProduct(productCart);
      this.productsCart = await this.cartService.getProducts();
      this.toastr.success(
        'Produto removido do carrinho com sucesso!',
        'Sucesso!'
      );
    } catch (error) {
      console.log(error);
      this.toastr.error(
        'Ocorreu um erro ao remover o produto do carrinho!',
        'Erro!'
      );
    } finally {
      await this.loaderService.hideLoader();
      setTimeout(() => {
        window.dispatchEvent(new Event('refreshOwlCarouselMore'));
      }, 500);
    }
  }

  getTotalValue(): number {
    let total = 0;
    this.productsCart.forEach((pc) => {
      total = total + (pc.quantity || 1) * (pc?.product?.salePrice || 0);
    });
    return total;
  }

  haveProductsCart() {
    return this.productsCart.length > 0;
  }

  onChangeQuantity(event: any, productCar: ProductCart) {
    this.loaderService.showLoader();
    const quantity = Number(event.target.value);
    if (quantity !== productCar.quantity && quantity !== 0) {
      productCar.quantity = quantity;
      this.cartService.changeQuantity(productCar);
    } else {
      if (quantity === 0) {
        event.target.value = productCar.quantity;
      }
    }
    this.loaderService.hideLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }

  onClickCheckout() {
    if (this.getTotalValue() < 400) {
      this.toastr.error(
        'Adicione mais itens ao seu carrinho.',
        'Valor mínimo de R$ 400,00 por pedido!'
      );
    } else {
      this.confirmationService.confirm({
        message: 'Confirma a geração do seu pedido?',
        header: 'Confirmação',
        accept: () => {
          this.checkout();
        },
      });
    }
  }

  async checkout() {
    await this.loaderService.showLoader();
    try {
      await this.customerService.createDelivery(this.productsCart);
      this.cartService.clear();
      this.router.navigate(['area-cliente']);
      this.toastr.success(
        'Aguarde o contato de um dos nossos consultores.',
        'Pedido realizado com sucesso!'
      );
    } catch (error: any) {
      this.toastr.error(error, 'Erro!');
    } finally {
      await this.loaderService.hideLoader();
      setTimeout(() => {
        window.dispatchEvent(new Event('refreshOwlCarouselMore'));
      }, 500);
    }
  }
}
