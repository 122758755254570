<div class="dont-cart">
  <div class="dont-cart-content">
    <h3>{{ title }}</h3>
    <button
      class="btn pink-gradient-btn-into-black"
      (click)="onClickShowProducts()"
    >
      COMPRAR PRODUTOS
    </button>
  </div>
</div>
