import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';

@Component({
  selector: 'app-privacy.page',
  templateUrl: './privacy.page.component.html',
  styleUrls: ['./privacy.page.component.scss'],
})
export class PrivacyPageComponent implements OnInit, AfterViewInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
  }

  ngAfterViewInit(): void {
    this.loaderService.hideLoader();
  }
}
