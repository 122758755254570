<div class="section-header">
  <h1>Quem Somos</h1>
</div>

<div class="section-content">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center wow slideInUp" data-wow-duration="2s">
        <p class="para_text">
          Fundada em 2005 a Distribuidora Santa Catarina nasceu com a proposta
          de trazer para os Canais Farma, Alimentar e Lojas especializadas,
          produtos que ofereçam para os PDVs, além de um portfólio diferenciado,
          agregar valor ao segmento de Higiene, Beleza e Cuidado Pessoal.
        </p>
        <p class="para_text">
          Com uma equipe de vendas altamente qualificada, levamos aos nossos
          clientes as melhores oportunidades de negócios e diferenciais para
          seus atendentes e consumidores. Primamos pela ética e transparência em
          todo processo comercial, oferecemos um atendimento qualificado e
          personalizado para cada canal de atuação.
        </p>
        <p class="para_text">
          Distribuímos marcas diferenciadas, algumas exclusivas, levando aos
          nossos clientes tendências de mercado, qualidade, os melhores preços e
          condições, garantindo competitividade e rentabilidade aos nossos
          parceiros.
        </p>
      </div>
    </div>
  </div>

  <div class="standalone-area">
    <div class="container">
      <div class="row standalone-row align-items-center">
        <div class="col-lg-12">
          <div class="blog-image wow hover-effect fadeInLeft">
            <img src="assets/img/about/about.jpg" alt="image" />
          </div>
        </div>
      </div>

      <div class="row standalone-row align-items-center">
        <div class="col-lg-12">
          <div class="d-inline-block">
            <h2 class="gradient-text1 pb-3">Missão</h2>
            <p>
              Ser referência no segmento de distribuição de cosméticos e
              perfumaria, tendo como parceiros os melhores fornecedores do ramo,
              facilitando a ligação entre indústria e varejo.
            </p>
          </div>
        </div>
      </div>

      <div class="row standalone-row align-items-center">
        <div class="col-lg-12">
          <div class="d-inline-block">
            <h2 class="gradient-text1 pb-3">Visão</h2>
            <p>
              Estar entre as melhores distribuidoras de cosméticos e perfumaria
              do Estado de Santa Catarina, buscando crescimento através de
              relações éticas e saudáveis.
            </p>
          </div>
        </div>
      </div>

      <div class="row standalone-row align-items-center">
        <div class="col-lg-12">
          <div class="d-inline-block">
            <h2 class="gradient-text1 pb-3">Valores</h2>
            <p>
              Prezamos por uma gestão humanizada, fazendo das pessoas nosso
              maior patrimônio. Buscamos constantemente melhorias e inovações em
              nossos processos, trazendo para nossos clientes o que há de melhor
              e de mais moderno em nosso segmento.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
